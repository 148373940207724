export const FREQUENCIES = {
	// hourly: {
	// 	id: "hourly",
	// 	name: "Hourly"
	// },
	never: {
		id: "never",
		name: "Never"
	},
	daily: {
		id: "daily",
		name: "Daily"
	},
	weekly: {
		id: "weekly",
		name: "Weekly"
	},
	monthly: {
		id: "monthly",
		name: "Monthly"
	},
	quarterly: {
		id: "quarterly",
		name: "Quarterly"
	},
	yearly: {
		id: "yearly",
		name: "Yearly"
	}
};

export const WEEKDAYS = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

export const RRULE_MONTH_WEEK = {
	first: {
		id: "first",
		name: "First",
		rrule: "BYSETPOS=1"
	},
	second: {
		id: "second",
		name: "Second",
		rrule: "BYSETPOS=2"
	},
	third: {
		id: "third",
		name: "Third",
		rrule: "BYSETPOS=3"
	},
	fourth: {
		id: "fourth",
		name: "Fourth",
		rrule: "BYSETPOS=4"
	},
	last: {
		id: "last",
		name: "Last",
		rrule: "BYSETPOS=-1"
	}
};

export const RRULE_MONTH_WEEK_DAY = [
	{
		id: "sunday",
		name: "Sunday",
		rrule: "SU"
	},
	{
		id: "monday",
		name: "Monday",
		rrule: "MO"
	},
	{
		id: "tuesday",
		name: "Tuesday",
		rrule: "TU"
	},
	{
		id: "wednesday",
		name: "Wednesday",
		rrule: "WE"
	},
	{
		id: "thursday",
		name: "Thursday",
		rrule: "TH"
	},
	{
		id: "friday",
		name: "Friday",
		rrule: "FR"
	},
	{
		id: "saturday",
		name: "Saturday",
		rrule: "SA"
	},
	{
		id: "day",
		name: "Day",
		rrule: "MO,TU,WE,TH,FR,SA,SU"
	},
	{
		id: "weekday",
		name: "Weekday",
		rrule: "MO,TU,WE,TH,FR"
	},
	{
		id: "weekendDay",
		name: "Weekend Day",
		rrule: "SA,SU"
	}
];

export const RRULE_END_TYPES = [
	{
		id: "never",
		name: "Never"
	},
	{
		id: "after",
		name: "After"
	},
	{
		id: "on",
		name: "On"
	}
];

export const RRULE_BYSETPOS = {
	"1": RRULE_MONTH_WEEK.first,
	"2": RRULE_MONTH_WEEK.second,
	"3": RRULE_MONTH_WEEK.third,
	"4": RRULE_MONTH_WEEK.fourth,
	"-1": RRULE_MONTH_WEEK.last
};

export const RRULE_MONTHS = [
	{
		id: 1,
		name: "January"
	},
	{
		id: 2,
		name: "February"
	},
	{
		id: 3,
		name: "March"
	},
	{
		id: 4,
		name: "April"
	},
	{
		id: 5,
		name: "May"
	},
	{
		id: 6,
		name: "June"
	},
	{
		id: 7,
		name: "July"
	},
	{
		id: 8,
		name: "August"
	},
	{
		id: 9,
		name: "September"
	},
	{
		id: 10,
		name: "October"
	},
	{
		id: 11,
		name: "November"
	},
	{
		id: 12,
		name: "December"
	}
];
