import Kichiri from "./KichiriService";
import UserService from "./UserService";
import ConfigurationService from "./ConfigurationService";

const CompanyService = {
	/**
	 * Fetches the companies info.
	 * @returns {Object}
	 */
	async get() {
		let userData = UserService.getUserData();
		try {
			let response = await Kichiri.company.get(
				{
					companyId: userData.companyId
				},
				{},
				userData.authToken
			);

			return response;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Updates a company's info, including a file if provided.
	 * @param {FormData} formData - The form data containing company info and the file.
	 */
	async update(formData) {
		let { authToken, companyId } = UserService.getUserData();

		try {
			await fetch(`${ConfigurationService.resolveApi().rest}/company/${companyId}/update`, {
				method: "POST",
				body: formData,
				headers: {
					authorization: authToken
				}
			});
			return;
		} catch (error) {
			console.log(error);
		}
	},
	/**
	 * Retrieves the company's logo as a blob.
	 *
	 * @returns {Promise<Blob|Object>} A Promise that resolves with the logo as a Blob if successful,
	 * or an object containing a null response and an error message if the request fails.
	 */
	async getLogo() {
		try {
			let { authToken, companyId } = await UserService.getUserData();
			let response = await fetch(`${ConfigurationService.resolveApi().rest}/company/${companyId}/logo`, {
				method: "GET",
				headers: {
					authorization: authToken
				}
			});
			if (response.status !== 200) {
				return { response: null, error: "Error occurred trying to retrieve company logo." };
			}
			return response.blob();
		} catch (error) {
			return { response: null, error };
		}
	}
};

export default CompanyService;
