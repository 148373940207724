import React from "react";
import "./toggle.css"; // Import your CSS file here
import clsx from "clsx";

class Toggle extends React.Component {
	handleClick = () => {
		const { id, onClick } = this.props;
		onClick(id);
	};

	render() {
		const { className, active, label = "", icon = null } = this.props;

		return (
			<button type="button" className={clsx("toggle", className, active && "toggle--active")} onClick={this.handleClick}>
				{icon && <div className={label && "toggle__icon"}>{icon}</div>}

				{label && <span className="toggle__label">{label}</span>}
			</button>
		);
	}
}

export default Toggle;
