import React from "react";
import _ from "lodash";
import moment from "moment";

import WComponent from "../../common/WComponent";

import "./date-range-filter.css";

import WORKFLOW from "../../../constants/Workflow";
import TextFieldInput from "../../common/text_field_input/TextFieldInput";
import { DateRange } from "react-date-range";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

export default class DateRangeFilter extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			selectedDateFunction: "all",
			statePayload: {}
		};
	}

	componentDidMount() {
		this.resetComponent();
	}

	componentDidUpdate(prevProps) {
		let { dateFunction } = this.props;

		if (prevProps.dateFunction !== dateFunction) {
			this.resetComponent();
		}
	}

	resetComponent = async () => {
		// dateFunction prop is passed as the initial value of whatever values we are setting
		// Anatomy: dateFunction = { dateFunction: "lastXDays", payload: { x: 7 } }
		let { dateFunction } = this.props;

		// Initialize the state payload to an empty object
		let payload = {};

		// Grab the actual payload data from the prop
		let inputPayload = dateFunction.payload;

		// Check if it's empty
		if (!_.isEmpty(inputPayload)) {
			// Go through each key in the input payload and migrate the keys to the state payload
			for (let key of Object.keys(inputPayload)) {
				let value = inputPayload[key];

				// XXX - Ugly specific changes for dateRange functions
				if (dateFunction.dateFunction === "dateRange") {
					value.startDate = moment(value.startDate).toDate();
					value.endDate = moment(value.endDate).toDate();
				}

				payload[key] = value;
			}
		}

		// Set the selected date function and the state payload
		await this.update({
			selectedDateFunction: dateFunction.dateFunction || "all",
			payload
		});
	};

	onSelect = async ({ dateFunctionSchema }) => {
		// Create an empty payload object for the state
		let payload = {};

		// Pull the payload schema from the dateFunction schema
		let payloadSchema = dateFunctionSchema.payload;

		if (!_.isEmpty(payloadSchema)) {
			// Initialize any payload state parameters for the selected dateFunction and payload schema from the constants
			for (let payloadSchemaItem of payloadSchema) {
				payload[payloadSchemaItem.name] = payloadSchemaItem.default;
			}
		}

		await this.update({
			selectedDateFunction: dateFunctionSchema.id,
			payload
		});

		// Notify the parent of the new dateFunction and payload values
		if (this.props.onSelect) {
			this.props.onSelect({ dateFunction: dateFunctionSchema.id, payload });
		}
	};

	renderDateFunctions() {
		let { selectedDateFunction } = this.state;
		let { dateFunctions } = WORKFLOW;

		return (
			<div className="pill-container pill-container--vertical">
				{Object.keys(dateFunctions).map(key => {
					let dateFunctionSchema = dateFunctions[key];

					let isSelectedDateFunction = dateFunctionSchema.id === selectedDateFunction;

					return (
						<div key={key}>
							<div className={`pill ${isSelectedDateFunction ? "pill--active" : ""} `} onClick={() => this.onSelect({ dateFunctionSchema })}>
								{dateFunctionSchema.display}
							</div>
							{this.renderPayload({ isSelectedDateFunction, payload: dateFunctionSchema.payload })}
						</div>
					);
				})}
			</div>
		);
	}

	renderPayloadInput({ payloadItem }) {
		if (payloadItem.type === "number") {
			return (
				<TextFieldInput
					title={payloadItem.name}
					name={""}
					onChange={async event => {
						let { selectedDateFunction, payload } = this.state;
						let newValue = event.target.value;

						payload[payloadItem.name] = newValue;

						await this.update({
							payload
						});

						if (this.props.onSelect) {
							this.props.onSelect({ dateFunction: selectedDateFunction, payload });
						}
					}}
					value={this.state.payload[payloadItem.name]}
					autoComplete="off"
				/>
			);
		} else if (payloadItem.type === "dateRange") {
			return (
				<DateRange
					onChange={async item => {
						let { selectedDateFunction, payload } = this.state;
						let newValue = item.selection;

						payload[payloadItem.name] = newValue;

						await this.update({
							payload
						});

						if (this.props.onSelect) {
							this.props.onSelect({ dateFunction: selectedDateFunction, payload });
						}
					}}
					showSelectionPreview={true}
					moveRangeOnFirstSelection={false}
					editableDateInputs={true}
					months={2}
					ranges={[this.state.payload[payloadItem.name]]}
					direction="horizontal"
				/>
			);
		}
	}

	renderPayload({ isSelectedDateFunction, payload }) {
		if (!payload || !isSelectedDateFunction) {
			return;
		}

		return payload.map(payloadItem => {
			return this.renderPayloadInput({ payloadItem });
		});
	}

	render() {
		return <div className="date-range-filter">{this.renderDateFunctions()}</div>;
	}
}
