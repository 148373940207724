import React from "react";
import Modal from "react-responsive-modal";
import Button from "../../common/button/Button";
import WComponent from "../../common/WComponent";
import TextFieldInput from "../../common/text_field_input/TextFieldInput";
import BudgetInput from "../../common/budget_input/BudgetInput";
import { toast as alert } from "react-toastify";
import EngagementService from "../../../services/EngagementService";
import moment from "moment";
import UtilityService from "../../../services/UtilityService";

export class BudgetModal extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			// state
			loading: false,
			resource: "",
			budget: "",
			actual: "",
			result: ""
		};
	}

	onSubmit = async () => {
		let { view } = this.props;
		let { onCreate } = this;

		if (view === "create") {
			await onCreate();
		}
	};

	isEmpty = () => {
		let { resource, budget } = this.state;
		return !(resource.length > 0 || budget.length > 0);
	};

	// New method to validate inputs
	validateInputs = () => {
		let { budget } = this.state;
		const { isEmpty } = this;
		let errorMessages = []; // Array to collect error messages

		if (isEmpty()) {
			errorMessages.push("Missing fields!");
		}

		// Validate budget input using validateBudgetFormat
		if (!UtilityService.validateBudgetFormat(budget)) {
			errorMessages.push("Please enter time in the format: 1h 30m or 1h or 30m");
		}

		return errorMessages; // Return collected error messages
	};

	clearModal = () => {
		let { update } = this;

		update({
			resource: "",
			budget: ""
		});
	};

	onClose = () => {
		const { clearModal } = this;
		const { onClose } = this.props;
		clearModal();
		onClose(true);
	};

	onCreate = async () => {
		await this.update({
			loading: true
		});

		let { resource, budget } = this.state;
		let { engagementId } = this.props;
		const { onClose } = this;
		const { validateInputs } = this;
		let created = false;

		let errorMessages = validateInputs();

		if (errorMessages.length > 0) {
			alert.error(errorMessages.join(" ")); // Display all error messages
			return created;
		}

		try {
			const sanitizedBudget = budget.trim().toString();
			await EngagementService.createBudget({ engagementId, resource, budget: sanitizedBudget });

			created = true;

			await this.update({
				loading: false
			});

			onClose();
		} catch (error) {
			console.log(error);

			alert.error("Create error!");
			created = false;
		}

		await this.update({
			loading: false
		});
	};

	// Define updateModal as a method
	updateModal() {}

	onChange = e => {
		let target = e.target;
		const { update } = this;

		update({
			[target.name]: target.value
		});
	};

	render() {
		let { resource, budget } = this.state;
		const { onChange, onSubmit } = this;

		const { show, onClose } = this.props;

		return (
			<Modal open={show} onClose={onClose} center>
				<div className="modal-title">Create Budget</div>

				<div className="modal-content">
					<TextFieldInput title="Resource" placeholder="e.g., Bookeeping" name="resource" onChange={onChange} value={resource} autoComplete="off" />
					<BudgetInput name="budget" value={budget} onChange={onChange} />
				</div>

				<div className="modal-footer">
					<Button text="Cancel" onClick={() => onClose(true)} view="secondary" />
					<Button text="Create" onClick={onSubmit} />
				</div>
			</Modal>
		);
	}
}
