import React, { Component } from "react";
import * as Icon from "react-feather";
import "./search-input.css";

class SearchInput extends Component {
	render() {
		const { value, onChange, placeholder = "Search...", title = "" } = this.props;

		return (
			<div className="search-input">
				{title && <span className="search-input__title">{title}</span>}

				<div className="search-input__container">
					<Icon.Search size={18} color="#000" />
					<input
						className="search-input__container__input"
						type="search"
						name="search"
						placeholder={placeholder}
						value={value}
						autoComplete="off"
						onChange={onChange}
					/>
				</div>
			</div>
		);
	}
}

export default SearchInput;
