const STATUS_SELECTOR_POSITIONS = {
	left: "left",
	right: "right"
};

const CONTAINER_TYPES = {
	statusSelector: "statusSelector",
	userAssigner: "userAssigner",
	notes: "notes"
};

let globalReferences = {};

const FloatingContainerService = {
	/**
	 * Stores a reference to the status selector we will use in this service
	 *
	 * @param {String} name
	 * @param {Component} ref
	 * @param {Number} width
	 * @param {Number} height
	 */
	storeReference({ name, ref, width, height }) {
		globalReferences[name] = {
			component: ref,
			componentWidth: width,
			componentHeight: height
		};
	},

	/**
	 * Opens an global status selector
	 *
	 * @param {String} name
	 * @param {Component} positionBeside
	 * @param {String} position
	 * @param {Function} onStatusSelect
	 * @returns
	 */
	openFloatingContainer({ name, positionBeside, position = STATUS_SELECTOR_POSITIONS.left, beforeShow }) {
		let { component } = this.getReference({ name });

		if (!component) {
			return;
		}

		this.positionBeside({ name, targetElement: positionBeside, position });

		// Dirty bit to set up special calls for different floating controls

		beforeShow(component);

		// component.setSelectCallback(onStatusSelect);
		// component.setFlowCode(flowCode);
		component.forceShow();
	},

	getReference({ name }) {
		let reference = globalReferences[name];

		if (!reference) {
			return null;
		}

		return reference;
	},

	/**
	 * Positions the status selector next to a target component
	 *
	 * @param {Component} targetElement
	 * @param {String} position
	 * @returns
	 */
	positionBeside({ name, targetElement, position = STATUS_SELECTOR_POSITIONS.left }) {
		let { component, componentWidth, componentHeight } = this.getReference({ name });

		if (!component) {
			return;
		}

		// Get x and y coordinates of the target element
		let { x, y, width } = targetElement.getBoundingClientRect();

		// Get edges of the screen as coordinates
		let windowRight = document.body.clientWidth;

		let windowBottom = (window.innerHeight / 7) * 10;

		// We will use some of margin between the emoji selector and the element
		let marginFromElement = 10;

		// We will use some margin from the screen if we accidentally go over
		let marginFromScreen = 20;

		// Center emoji selector verticaly with this element
		let top = y - Math.floor(componentHeight / 2);

		// Prevent going off screen at the top
		if (top < 0) {
			top = marginFromScreen;
		}

		// Prevent going off screen at the bottom
		if (top + componentHeight > windowBottom) {
			top = windowBottom - componentHeight - marginFromScreen;
		}

		let left = 0;
		if (position === STATUS_SELECTOR_POSITIONS.left) {
			left = x - componentWidth - marginFromElement;
			// left = x - marginFromElement;
		} else {
			// If we want to position to the right of the target element
			left = x + width + marginFromElement;
		}

		// Prevent going off screen at the left
		if (left < 0) {
			left = marginFromScreen;
		}

		// Prevent going off screen at the right
		if (left + componentWidth > windowRight) {
			left = windowRight - componentWidth - marginFromScreen;
		}

		component.setPosition({ top, left });
	}
};

export default FloatingContainerService;
