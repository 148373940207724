import React from "react";
import Modal from "react-responsive-modal";

import CLIENTS from "../../../constants/Clients";

import WComponent from "../../common/WComponent";
import SearchableDropdown from "../../common/searchable_dropdown/SearchableDropdown";

import CustomFieldService from "../../../services/CustomFieldService";
import TextFieldInput from "../../common/text_field_input/TextFieldInput";
import UserService from "../../../services/UserService";
import Button from "../../common/button/Button";

import "./client-filter-modal.css";

export default class ClientFilterModal extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			show: false,

			statuses: [
				{ id: "active", display: "Active" },
				{ id: "all", display: "All" }
			],
			yearEnds: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],

			status: "active",

			partnerId: 0,
			seniorManagerId: 0,
			managerId: 0,
			teamId: 0,

			customFields: [],

			selectedTeamName: "",
			selectedPartnerName: "",
			selectedSeniorManagerName: "",
			selectedManagerName: "",

			selectedYearEnds: {}
		};
	}

	componentDidMount() {
		this.resetComponent();
	}

	componentDidUpdate(prevProps) {
		let { show } = this.props;

		if (prevProps.show !== show) {
			this.resetComponent();
		}
	}

	resetComponent = async () => {
		let { show, filters } = this.props;

		let { partnerId = 0, seniorManagerId = 0, managerId = 0, teamId = 0, yearEnds = [], customFields = [], status = "active" } = filters;

		let parsedYearEnds = {};

		for (let yearEnd of yearEnds) {
			parsedYearEnds[yearEnd] = true;
		}

		await this.update({
			partnerId,
			seniorManagerId,
			managerId,
			teamId,

			selectedPartnerName: await UserService.fetchUserName({ userId: partnerId }),
			selectedSeniorManagerName: await UserService.fetchUserName({ userId: seniorManagerId }),
			selectedManagerName: await UserService.fetchUserName({ userId: managerId }),
			selectedTeamName: await UserService.fetchTeamName({ teamId }),

			selectedYearEnds: parsedYearEnds,
			customFields,
			status
		});

		await this.fetchCustomFields();

		await this.update({
			show
		});
	};

	onClear = async () => {
		await this.clearCustomFields();

		await this.update({
			status: "active",

			partnerId: 0,
			seniorManagerId: 0,
			managerId: 0,
			teamId: 0,

			selectedTeamName: "",
			selectedPartnerName: "",
			selectedSeniorManagerName: "",
			selectedManagerName: "",

			selectedYearEnds: {}
		});
	};

	clearCustomFields = async () => {
		let { customFields } = this.state;

		let updateCustomFields = customFields.slice();

		for (let cf of updateCustomFields) {
			cf.value = "";
		}

		await this.update({
			customFields: updateCustomFields
		});
	};

	fetchCustomFields = async () => {
		let { customFields } = this.state;
		let newCustomFields = await CustomFieldService.fetchFields();

		for (let newCustomField of newCustomFields) {
			let [oldCustomField] = customFields.filter(field => field.id === newCustomField.id);

			if (!oldCustomField) {
				continue;
			}

			newCustomField.value = oldCustomField.value;
		}

		await this.update({
			customFields: newCustomFields.slice()
		});
	};

	onClose = async () => {
		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	onChangePartner = item => {
		this.update({
			partnerId: item.id,
			selectedPartnerName: item.name
		});
	};

	onChangeSeniorManager = item => {
		this.update({
			seniorManagerId: item.id,
			selectedSeniorManagerName: item.name
		});
	};

	onChangeManager = item => {
		this.update({
			managerId: item.id,
			selectedManagerName: item.name
		});
	};

	onChangeTeam = item => {
		this.update({
			teamId: item.id,
			selectedTeamName: item.name
		});
	};

	onApply = () => {
		let { partnerId, seniorManagerId, managerId, teamId, selectedYearEnds, customFields, status } = this.state;

		let parsedCustomFields = [];

		for (let cf of customFields) {
			if (cf.value && cf.value.length > 0) {
				parsedCustomFields.push({ id: cf.id, value: cf.value });
			}
		}

		let parsedYearEnds = Object.keys(selectedYearEnds);

		if (this.props.onFilterChange) {
			this.props.onFilterChange({
				filters: {
					partnerId,
					seniorManagerId,
					managerId,
					teamId,
					customFields: parsedCustomFields,
					yearEnds: parsedYearEnds,
					status
				}
			});
		}

		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	onCustomFieldChange = ({ customField, newValue }) => {
		let { customFields } = this.state;

		customFields = customFields.slice();

		let [updatedCustomField] = customFields.filter(cf => cf.id === customField.id);

		updatedCustomField.value = newValue;

		this.update({
			customFields
		});
	};

	onYearEndClicked = async ({ yearEnd }) => {
		let { selectedYearEnds } = this.state;

		if (typeof selectedYearEnds[yearEnd] !== "undefined") {
			delete selectedYearEnds[yearEnd];
		} else {
			selectedYearEnds[yearEnd] = true;
		}

		await this.update({
			selectedYearEnds
		});
	};

	onStatusSelected = async ({ statusItem }) => {
		await this.update({ status: statusItem.id });
	};

	renderYearEnds = () => {
		let { yearEnds, selectedYearEnds } = this.state;

		return (
			<>
				<div className="client-filter-modal__general__label">Year Ends</div>
				<div className="pill-container pill-container--filter-view">
					{yearEnds.map((yearEnd, index) => {
						return (
							<div
								className={`pill ${selectedYearEnds[yearEnd] ? "pill--active" : ""} `}
								onClick={() => this.onYearEndClicked({ yearEnd })}
								key={`${index}-${yearEnd}`}
							>
								{yearEnd}
							</div>
						);
					})}
				</div>
			</>
		);
	};

	renderStatuses = () => {
		let { status, statuses } = this.state;

		return (
			<>
				<div className="client-filter-modal__general__label">Status</div>
				<div className="pill-container pill-container--filter-view">
					{statuses.map((statusItem, index) => {
						return (
							<div
								className={`pill ${status === statusItem.id ? "pill--active" : ""} `}
								onClick={() => this.onStatusSelected({ statusItem })}
								key={`${index}-${statusItem.id}`}
							>
								{statusItem.display}
							</div>
						);
					})}
				</div>
			</>
		);
	};

	renderCustomFields = () => {
		let { customFields } = this.state;

		return customFields.map((cf, index) => {
			if (cf.type === CLIENTS.customFieldTypes.string) {
				return (
					<TextFieldInput
						containerClass="client-filter-modal__input-c"
						title={cf.name}
						name={cf.field}
						onChange={event => this.onCustomFieldChange({ customField: cf, newValue: event.target.value })}
						value={cf.value || ""}
						autoComplete="off"
						key={index}
					/>
				);
			} else if (cf.type === CLIENTS.customFieldTypes.list) {
				return (
					<SearchableDropdown
						containerClass="client-filter-modal__input-c"
						title={cf.name}
						type="customFieldList"
						customFieldId={cf.id}
						selectedName={cf.value}
						onSelect={item => this.onCustomFieldChange({ customField: cf, newValue: item.name })}
						showAll
						key={index}
					/>
				);
			}

			return null;
		});
	};

	render() {
		let { show, selectedTeamName, selectedPartnerName, selectedSeniorManagerName, selectedManagerName } = this.state;
		let { onClose } = this.props;

		return (
			<Modal open={show} onClose={onClose} center>
				<div className="client-filter-modal">
					<div className="modal-title">Filters</div>
					<div className="client-filter-modal__body">
						<div className="client-filter-modal__body__inputs">
							<SearchableDropdown
								containerClass="client-filter-modal__input-c"
								title="Team"
								type="teams"
								onSelect={this.onChangeTeam}
								selectedName={selectedTeamName}
								showAll
								prependUnassigned
							/>
							<SearchableDropdown
								containerClass="client-filter-modal__input-c"
								title="Partner"
								type="partners"
								onSelect={this.onChangePartner}
								selectedName={selectedPartnerName}
								showAll
							/>

							<SearchableDropdown
								containerClass="client-filter-modal__input-c"
								title="Senior Manager"
								type="users"
								onSelect={this.onChangeSeniorManager}
								selectedName={selectedSeniorManagerName}
								showAll
								prependUnassigned
							/>
							<SearchableDropdown
								containerClass="client-filter-modal__input-c"
								title="Manager"
								type="users"
								onSelect={this.onChangeManager}
								selectedName={selectedManagerName}
								showAll
								prependUnassigned
							/>

							{this.renderYearEnds()}

							{this.renderStatuses()}
						</div>

						<div className="client-filter-modal__more">{this.renderCustomFields()}</div>
					</div>
				</div>

				<div className="modal-footer">
					<Button text="Cancel" view="secondary" onClick={onClose} />
					<Button text="Clear" view="secondary" onClick={this.onClear} />
					<Button text="Apply" onClick={this.onApply} />
				</div>
			</Modal>
		);
	}
}
