import React from "react";
import * as Icon from "react-feather";
import { Pin as IconPin, PinOff as IconPinOff } from "lucide-react";
import moment from "moment";
import { toast as alert } from "react-toastify";
import clsx from "clsx";

import NoteService from "../../services/NoteService";
import NoteModal from "./note-modal/NoteModal";

import { ORDER_QUERIES } from "../../constants/Tasks";
import WComponent from "../common/WComponent";
import PageHeading from "../common/page_heading/PageHeading";
import Button from "../common/button/Button";
import Loader from "../common/loader/Loader";
import UtilityService from "../../services/UtilityService";

class Notes extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,

			notes: [],
			noteId: "new",
			type: "general"
		};

		this.noteModal = null;
	}

	componentDidMount() {
		this.resetComponent();
	}

	componentDidUpdate(prevProps) {
		let newProps = this.props;
		let oldProps = prevProps;

		if (oldProps.engagementId !== newProps.engagementId || oldProps.clientId !== newProps.clientId) {
			this.resetComponent();
		}
	}

	async resetComponent() {
		let { engagementId, clientId, pinnedOnly, type } = this.props;

		await this.update({
			engagementId: engagementId || 0,
			clientId: clientId || 0,
			loading: true,
			pinnedOnly: pinnedOnly ? true : undefined,
			type: type || "general"
		});

		await this.fetchNotes();
		await this.update({ loading: false });
	}

	async fetchNotes() {
		let { engagementId, clientId, pinnedOnly, type } = this.state;
		let notes = await NoteService.fetchNotes({ clientId, engagementId, isPinned: pinnedOnly });

		if (type) {
			notes = notes.filter(note => {
				if (type === "general") {
					return !note.type || note.type === "general";
				}
				return note.type === type;
			});
		}

		await this.update({
			notes
		});
	}

	onNewTask = async () => {
		await this.update({ noteId: "new" });
		this.noteModal.show();
	};

	onNoteSelect = async note => {
		await this.update({ noteId: note.id });
		this.noteModal.show();
	};

	onNoteClose = async () => {
		await this.update({ loading: true });
		await this.fetchNotes();
		await this.update({ loading: false });
	};

	onPinNote = async note => {
		let { type } = this.state;
		let success = await NoteService.updateNote({
			noteId: note.id,
			isPinned: !note.is_pinned
		});

		if (!success) {
			alert.info(`Failed to pin ${UtilityService.getNoteType({ capitalizeFirstLetter: false, type })}`);
		} else {
			alert.info(`${UtilityService.getNoteType({ capitalizeFirstLetter: false, type })} updated!`);
		}

		await this.fetchNotes();
	};

	renderSortIcon(field) {
		let { sortField, sortOrder } = this.state;

		return sortField === field && (sortOrder === "desc" ? <Icon.ChevronUp size={14} /> : <Icon.ChevronDown size={14} />);
	}

	render() {
		let { loading, notes, noteId, pinnedOnly, type } = this.state;
		return (
			<div className="container">
				<PageHeading title={`${UtilityService.getNoteType({ capitalizeFirstLetter: true, type })}s`} />
				<div className="container-header">
					<div className="container-header-options">
						<Button view="secondary" icon={<Icon.Plus size={18} />} onClick={this.onNewTask} />
					</div>
				</div>

				{loading && (
					<div className="container-loader">
						<Loader />
					</div>
				)}

				{notes.length === 0 && !loading && (
					<div className="container-empty">
						<div>
							<Icon.Frown size={64} />
						</div>
						<div>... no {UtilityService.getNoteType({ capitalizeFirstLetter: false, type })}s ...</div>
					</div>
				)}

				{notes.length > 0 && !loading && (
					<div className={clsx("t", "t--compact")}>
						<div className="t__header">
							<div className="t__header__item" />
							<div onClick={() => this.sortBy(ORDER_QUERIES.name)} className="t__header__item">
								Note {this.renderSortIcon(ORDER_QUERIES.name)}
							</div>
							<div onClick={() => this.sortBy(ORDER_QUERIES.description)} className="t__header__item">
								Author {this.renderSortIcon(ORDER_QUERIES.description)}
							</div>
							<div onClick={() => this.sortBy(ORDER_QUERIES.assignedUser)} className="t__header__item">
								Date {this.renderSortIcon(ORDER_QUERIES.assignedUser)}
							</div>
						</div>

						{notes.map((note, index) => {
							let odd = index % 2 === 0;
							return (
								<div className={clsx("t__row", { "t__row--stripe": odd })} key={index}>
									<div className="t__row__item" onClick={() => this.onPinNote(note)}>
										{note.is_pinned ? <IconPinOff size={14} /> : <IconPin size={14} />}
									</div>

									<div className="t__row__item" onClick={() => this.onNoteSelect(note)}>
										{note.content}
									</div>
									<div className="t__row__item">{note.User ? `${note.User.first_name} ${note.User.last_name}` : "Unassigned"}</div>
									<div className="t__row__item">{moment(note.created_at).format("MMMM Do YYYY @ h:mma")}</div>
								</div>
							);
						})}
					</div>
				)}

				<NoteModal ref={ref => (this.noteModal = ref)} noteId={noteId} shouldPin={pinnedOnly} onClose={this.onNoteClose} {...this.props} type={type} />
				{/* <TaskModal ref={(ref) => (this.taskModal = ref)} taskId={this.state.taskId} engagementId={this.state.engagementId} onClose={this.onTaskClose} {...this.props} /> */}
			</div>
		);
	}
}

export default Notes;
