import React from "react";

import WComponent from "../WComponent";

import UtilityService from "../../../services/UtilityService";

import "../searchable_dropdown/searchable-dropdown.css";
import "./select.css";
import clsx from "clsx";

const KEYS = {
	up: 38,
	down: 40,
	enter: 13,
	tab: 9,
	esc: 27,
	semiColon: 186,
	backspace: 8
};

class Select extends WComponent {
	constructor(props) {
		super(props);

		// props: {
		//     alwaysShow,// Will always show the list regardless of focus
		//     showAll, // Will show all options on focus (through click or tab)
		//     prependUnassigned,// Will prepend an "unassigned" option at the start of the list
		//     clearOnSelect // Will clear the field once an item is selected
		// }

		this.state = {
			show: false,
			hoverIndex: 0,
			isListHover: false,

			search: props.selectedName,
			results: [],

			showAll: props.showAll,
			prependUnassigned: props.prependUnassigned,
			isFocus: false,

			firstFocus: false,

			searchListStyles: {}
		};

		this.onSelect = this.onSelect.bind(this);

		this.searchListRef = null;
		this.inputComponentRef = null;
	}

	componentDidMount = () => {
		let { alwaysShow } = this.props;

		if (alwaysShow) {
			this.executeSearch();
		}
	};

	componentDidUpdate = prevProps => {
		let { selectedName } = this.props;
		if (prevProps.selectedName !== selectedName) {
			this.update({
				search: selectedName
			});
		}
	};

	executeSearch = async () => {
		let { onSearch } = this.props;
		let { search, prependUnassigned, firstFocus, showAll } = this.state;

		let searchTerm = firstFocus && showAll ? "" : search;

		// let newResults = await UserService.fetchTeams({ search: searchTerm });
		let newResults = await onSearch({ searchTerm });

		if (prependUnassigned) {
			newResults.unshift({
				id: 0,
				name: "Select ..."
			});
		}

		await this.update({
			results: newResults,
			firstFocus: false
		});
	};

	onSearchChange = async e => {
		let { showAll } = this.state;

		let value = e.target.value;

		await this.update({
			search: value,
			show: showAll || (value.length > 0 && !showAll),
			hoverIndex: 0
		});

		await this.executeSearch();
	};

	clear = async () => {
		await this.update({
			show: false,
			search: ""
		});
	};

	async onSelect({ item }) {
		let { clearOnSelect } = this.props;

		if (this.props.onSelect) {
			this.props.onSelect(item);
		}

		await this.update({
			show: false,
			search: clearOnSelect ? "" : item.name
		});
	}

	onInputFocus = async () => {
		let { showAll } = this.state;

		await this.update({
			isFocus: true,
			show: showAll,
			firstFocus: true
		});

		this.executeSearch();
	};

	onInputBlur = async event => {
		await UtilityService.timeout(100);

		await this.update({
			show: false,
			isFocus: false
		});
	};

	onKeyDown = async e => {
		let { hoverIndex, results } = this.state;

		// If the user hits the ENTER key, select the current item
		if (e.keyCode === KEYS.enter) {
			e.preventDefault();
			this.onSelect({ item: results[hoverIndex] });
		}

		// If the user hits the UP arrow while searching
		else if (e.keyCode === KEYS.up) {
			e.preventDefault();

			// If the selector is at the top of the a list, jump to the bottom
			if (hoverIndex === 0) {
				hoverIndex = results.length - 1;
			}
			// In all other cases move the selector up by 1 chat
			else {
				hoverIndex--;
			}
		}
		// If the user hits the DOWN arrow while searching
		else if (e.keyCode === KEYS.down) {
			e.preventDefault();

			// And the selector is on the last item on the list, move to the top of the list
			if (hoverIndex === results.length - 1) {
				hoverIndex = 0;
			}
			// Otherwise move the selector down by 1 item
			else {
				hoverIndex++;
			}
		} else if (e.keyCode === KEYS.esc) {
			setTimeout(() => {
				this.update({
					isFocus: false,
					show: false
				});
			}, 100);
		}

		await this.update({ hoverIndex, isListHover: false });

		if (this.currentResult) {
			this.currentResult.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
		}
	};

	onMouseEnter = async index => {
		await this.update({
			hoverIndex: index
		});
	};

	onMouseLeave = async index => {
		await this.update({
			hoverIndex: index
		});
	};

	createIndexReference = (index, ref) => {
		let { hoverIndex } = this.state;

		if (hoverIndex === index) {
			this.currentResult = ref;
		}
	};

	render() {
		let { searchPlaceHolder, search, show, results, hoverIndex, isListHover, searchListStyles } = this.state;
		let { title, alwaysShow, containerClass, className } = this.props;

		let searchFieldListClasses = ["select__options"];

		if (!title) {
			searchFieldListClasses.push("select__options--no-title");
		}

		return (
			<div className={clsx("select", containerClass)}>
				{title && <span className="select__title">{title}</span>}

				<input
					ref={ref => (this.inputComponentRef = ref)}
					className={clsx("input-field__input", className)}
					type="text"
					name="search"
					onKeyDown={this.onKeyDown}
					onChange={this.onSearchChange}
					onFocus={this.onInputFocus}
					onBlur={this.onInputBlur}
					placeholder={searchPlaceHolder}
					value={search}
					autoComplete="off"
				/>
				{(show || alwaysShow) && results.length > 0 && (
					<div ref={ref => (this.searchListRef = ref)} className={searchFieldListClasses.join(" ")} style={searchListStyles}>
						{results.map((item, index) => {
							let styles = {};
							let classes = ["select__options__item"];

							if (item.color) {
								styles.backgroundColor = item.color;
							}

							if (hoverIndex === index && !isListHover) {
								classes.push("select__options__item--hover");
							}

							classes = classes.join(" ");

							return (
								<div
									ref={ref => this.createIndexReference(index, ref)}
									style={styles}
									className={classes}
									key={item.id}
									onMouseEnter={() => this.onMouseEnter(index)}
									onMouseLeave={() => this.onMouseLeave(index)}
									onMouseDown={() => {
										this.onSelect({ item });
									}}
								>
									{item.name}
								</div>
							);
						})}
					</div>
				)}
			</div>
		);
	}
}

export default Select;
