import React from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

import UtilityService from "../../../services/UtilityService";

import Select from "../Select/Select";
import WComponent from "../WComponent";

import "./relative-date.css";

import { DEFAULT_RELATIVE_DUE_DATE } from "../../../constants/EngagementTemplates";
import clsx from "clsx";

class RelativeDate extends WComponent {
	constructor(props) {
		super(props);

		let dateType;
		let value;

		try {
			dateType = props.data.dateType;
			value = props.data.value;
		} catch (error) {
			dateType = DEFAULT_RELATIVE_DUE_DATE.dateType;
			value = DEFAULT_RELATIVE_DUE_DATE.value;
		}

		this.state = {
			dateType,
			value
		};
	}

	async componentDidMount() {
		this.resetComponent();
	}

	componentDidUpdate(prevProps) {
		let { data } = this.props;

		if (JSON.stringify(prevProps.data) !== JSON.stringify(data)) {
			this.resetComponent();
		}
	}

	async resetComponent() {
		let { data } = this.props;

		let dateType;
		let value;

		try {
			dateType = data.dateType;
			value = data.value;
		} catch (error) {
			dateType = DEFAULT_RELATIVE_DUE_DATE.dateType;
			value = DEFAULT_RELATIVE_DUE_DATE.value;
		}

		await this.update({
			dateType,
			value
		});
	}

	onDateTypeChange = async dateType => {
		let { value } = this.state;
		let { onChange } = this.props;

		await this.update({ dateType });

		if (onChange) {
			onChange({ dateType, value });
		}
	};

	onValueChange = async rangeValue => {
		let { dateType } = this.state;
		let value = rangeValue;

		let { onChange } = this.props;

		await this.update({ value });

		if (onChange) {
			onChange({ dateType, value });
		}
	};

	render() {
		let { dateType, value } = this.state;
		let { title, containerClass, className } = this.props;
		let { onValueChange } = this;

		return (
			<div className={clsx("relative-date", containerClass)}>
				<div className="relative-date__select">
					<Select
						title={title || "Due Date"}
						onSearch={({ searchTerm }) => [
							{ id: "startOfTheMonth", name: "Start of the Month" },
							{ id: "dayOfTheMonth", name: "Day of the Month" },
							{ id: "endOfTheMonth", name: "End of the Month" },
							{ id: "daysAfter", name: "Days After" }
						]}
						onSelect={this.onDateTypeChange}
						selectedName={dateType.name}
						showAll
					/>
				</div>
				{dateType.id === "daysAfter" && (
					<>
						<Slider
							min={0}
							max={45}
							value={value}
							style={{ WebkitTapHighlightColor: "var(--company-color)", width: "100%" }}
							onChange={onValueChange}
						/>
						<div className="relative-date__days">+ {value} days after</div>
					</>
				)}
				{dateType.id === "dayOfTheMonth" && (
					<>
						<Slider min={2} max={30} value={value} style={{ width: "100%" }} onChange={onValueChange} />
						<div className="relative-date__days">{UtilityService.getOrdinal(value)} of the month</div>
					</>
				)}
			</div>
		);
	}
}

export default RelativeDate;
