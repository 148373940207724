import React, { Component } from "react";

import FloatingContainerService from "../../../services/FloatingContainerService";
import FloatingStatusSelector from "./FloatingStatusSelector";

class FloatingStatusSelectorContainer extends Component {
	constructor(props) {
		super(props);

		this.floatingStatusSelectorComponentRef = null;
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	componentDidMount() {
		FloatingContainerService.storeReference({
			name: "statusSelector",
			ref: this.floatingStatusSelectorComponentRef,
			width: 465,
			height: 560
		});
	}

	render() {
		return <FloatingStatusSelector ref={ref => (this.floatingStatusSelectorComponentRef = ref)} />;
	}
}

export default FloatingStatusSelectorContainer;
