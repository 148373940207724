// External Libraries
import React from "react";

// Common Components
import WComponent from "../../../common/WComponent";

// Styling
import "./reconciliation-tool.css";

class ReconTable extends WComponent {
	constructor(props) {
		super(props);
		this.state = {};
	}

	formatNumber(num) {
		const number = Number(num);

		if (isNaN(number)) {
			return "";
		}

		const options = Number.isInteger(number) ? {} : { minimumFractionDigits: 2 };
		return number.toLocaleString("en-US", options);
	}

	renderStripeClasses({ index }) {
		let listItemClasses = ["list-item"];
		let odd = index % 2 === 0;

		if (odd) {
			listItemClasses.push("list-item--stripe");
		}

		return listItemClasses.join(" ");
	}

	render() {
		let { currentYearSummary } = this.props;

		return (
			<>
				<div className="recon-tables">
					<div className="recon-tables__item">
						<div className="recon-tables__item__title">Canada Revenue Agency</div>
						<div className="t__header">
							<div className="list-column list-column--s">DATE</div>
							<div className="list-column list-column--xl">DESCRIPTION</div>
							<div className="list-column list-column--s">AMOUNT $</div>
						</div>

						{currentYearSummary.map((link, index) => (
							<div key={index} className={this.renderStripeClasses({ index })}>
								<div className="list-column list-column--s">
									<p>{link.date}</p>
								</div>
								<div className="list-column list-column--xl">
									<p>{link.description}</p>
								</div>
								<div className="list-column list-column--s">
									<p>{this.formatNumber(link.amount)}</p>
								</div>
							</div>
						))}
					</div>

					<div className="recon-tables__spacer" />

					<div className="recon-tables__item">
						<div className="recon-tables__item__title">General Ledger</div>
						<div className="t__header">
							<div className="list-column list-column--s">DATE</div>
							<div className="list-column list-column--xl">DESCRIPTION</div>
							<div className="list-column list-column--s">AMOUNT $</div>
							<div className="list-column list-column--s">DIFF $</div>
						</div>

						{currentYearSummary.map((link, index) => (
							<div key={index} className={this.renderStripeClasses({ index })}>
								<div className="list-column list-column--s">
									<p>{link.date2}</p>
								</div>
								<div className="list-column list-column--xl">
									<p>{link.description2}</p>
								</div>
								<div className="list-column list-column--s">
									<p>{this.formatNumber(link.amount2)}</p>
								</div>
								<div className="list-column list-column--s">
									<p>{this.formatNumber(link.differences)}</p>
								</div>
							</div>
						))}
					</div>
				</div>
			</>
		);
	}
}

export default ReconTable;
