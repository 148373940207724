import Kichiri from "./KichiriService";
import UserService from "./UserService";

const AuthService = {
	/**
	 * Logins user into the application
	 *
	 * @param {String} email
	 * @param {String} password
	 */
	async login({ email, password }) {
		try {
			let response = await Kichiri.user.login({
				email,
				password
			});

			UserService.storeUserData(response.data);
		} catch (error) {
			console.log(error);
		}
	},
	/**
	 * Logout user out of the application
	 * @returns {Object}
	 */
	async logout() {
		let userData = UserService.getUserData();
		try {
			let response = await Kichiri.user.logout({}, {}, userData.authToken);
			UserService.clearUserData();
			return response;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * @description Send reset password email
	 * @param {object} params
	 * @param {String} params.email
	 * @returns {Promise<{isErrored?: boolean,  message: string}>}
	 */
	async resetPassword({ email }) {
		try {
			await Kichiri.password.sendResetPasswordToken({ email });
			return { message: "Reset password email has been sent" };
		} catch (error) {
			console.error("Error: " + error.response.data.message);
			return { isErrored: true, message: error.response.data.message };
		}
	},

	/**
	 * Validate reset token
	 * @param {String} token
	 * @returns {String} email
	 *
	 */
	async validateResetToken({ token, email }) {
		try {
			let response = await Kichiri.password.verifyResetPasswordToken({ token, email });
			return response;
		} catch (error) {
			console.error("Error: " + error.response.data.message);
			throw error;
		}
	},

	/**
	 *
	 * @param {object} param
	 * @param {string} param.token
	 * @param {string} param.email
	 * @param {string} param.password
	 * @param {string} param.confirmPassword
	 * @returns {boolean}
	 */
	async updatePassword({ token, email, password, confirmPassword }) {
		try {
			await Kichiri.password.resetPassword({ token, email, password, confirmPassword });
			return true;
		} catch (error) {
			console.error("Error: " + error.response.data.message);
			return false;
		}
	},

	/*
	 * Validates the user token
	 * @returns {void}
	 */
	async validateToken() {
		let userData = UserService.getUserData();
		try {
			await Kichiri.user.validateToken({}, {}, userData.authToken);
		} catch (error) {
			console.log(error);
		}
	}
};

export default AuthService;
