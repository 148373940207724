import React from "react";
import Modal from "react-responsive-modal";
import * as Icon from "react-feather";

import WComponent from "../../common/WComponent";
import UserAssigner from "../../common/user_assigner/UserAssigner";
import TextFieldInput from "../../common/text_field_input/TextFieldInput";
import Checkbox from "../../common/checkbox/Checkbox";
import EngagementService from "../../../services/EngagementService";

import "./view-modal.css";
import Button from "../../common/button/Button";

export default class EngagementFilterModal extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			show: false,
			showDeleteModal: false,

			type: props.type || "engagement",

			viewId: null,
			name: "",
			isShared: false,
			assignedUsers: [],
			assignedTeams: []
		};
	}

	componentDidMount() {
		this.resetComponent();
	}

	componentDidUpdate(prevProps) {
		let { show } = this.props;

		if (prevProps.show !== show) {
			this.resetComponent();
		}
	}

	resetComponent = async () => {
		let { show, viewId } = this.props;

		if (!viewId) {
			await this.update({
				show,

				viewId: null,
				name: "",
				isShared: false,
				assignedUsers: [],
				assignedTeams: []
			});

			return;
		}

		await this.fetchView({ viewId });
	};

	fetchView = async ({ viewId }) => {
		let { show } = this.props;
		let { id, name, is_shared, users } = await EngagementService.fetchView({ viewId });

		let assignedUsers = users.map(user => {
			return {
				user_id: user.id,
				role_id: 0,

				// render part
				userName: `${user.first_name} ${user.last_name}`,
				roleName: "Staff"
			};
		});

		await this.update({
			show,

			viewId: id,
			name,
			isShared: is_shared,
			assignedUsers
		});
	};

	onClose = async () => {
		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	onChange = e => {
		let target = e.target;
		let value = target.type === "checkbox" ? target.checked : target.value;
		this.setState({
			[target.name]: value
		});
	};

	onSave = () => {
		let { viewId, name, isShared, assignedUsers, assignedTeams } = this.state;

		if (this.props.onSave) {
			this.props.onSave({
				viewId,
				name,
				isShared,
				assignedUsers,
				assignedTeams
			});
		}
	};

	onAssignedUsersChanged = async ({ selectedUsers }) => {
		await this.update({
			assignedUsers: selectedUsers
		});
	};

	isNewView = () => {
		let { viewId } = this.props;
		return viewId === null;
	};

	onDelete = () => {
		this.update({
			showDeleteModal: true
		});
	};

	onCloseDeleteModal = confirmed => {
		let { viewId } = this.state;

		this.update({
			showDeleteModal: false
		});

		if (confirmed) {
			if (this.props.onDelete) {
				this.props.onDelete({
					viewId,
					status: "deleted"
				});
			}
		}
	};

	render() {
		let { show, name, isShared, assignedUsers, showDeleteModal } = this.state;
		let { onClose } = this.props;

		let { isNewView, onChange, onAssignedUsersChanged, onCloseDeleteModal, onDelete, onSave } = this;

		return (
			<>
				<Modal open={show} onClose={onClose} center>
					<div className="view-modal">
						<div className="modal-title">{isNewView() ? "Save new view ..." : `Update view ...`}</div>
						<div className="view-modal__body">
							<TextFieldInput title="View Name" name="name" onChange={onChange} value={name} autoComplete="off" />
							<Checkbox containerClass="view-modal__body__checkbox-c" title="Shared" name="isShared" checked={isShared} onChange={onChange} />
							{isShared && <UserAssigner onChange={onAssignedUsersChanged} hideRolePicker users={assignedUsers} />}
						</div>
					</div>
					<div className="modal-footer">
						<Button view="secondary" icon={<Icon.Trash2 size={18} />} onClick={onDelete} />

						<Button view="secondary" text="Cancel" onClick={onClose} />

						<Button text="Save" onClick={onSave} />
					</div>
				</Modal>
				<Modal open={showDeleteModal} onClose={onCloseDeleteModal} center>
					<div className="view-modal-delete">
						<div className="modal-title">Delete View?</div>
						<div className="view-modal-delete__body">Are you sure you want to delete this view? It will be deleted for other users as well.</div>
					</div>
					<div className="modal-footer">
						<Button text="No" onClick={() => onCloseDeleteModal(false)} />
						<Button text="Yes" onClick={() => onCloseDeleteModal(true)} />
					</div>
				</Modal>
			</>
		);
	}
}
