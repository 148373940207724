import UserService from "./UserService";
import Kichiri from "./KichiriService";

const EventService = {
	/**
	 * Fetches list of events
	 * @param {Number} clientId
	 * @param {Number} engagementId
	 * @returns {Array}
	 */
	async fetchEvents({ clientId = null, engagementId = null }) {
		let user = UserService.getUserData();

		try {
			let response = await Kichiri.event.list(
				{},
				{
					clientId,
					engagementId
				},
				user.authToken
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return [];
	}
};

export default EventService;
