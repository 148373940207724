import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/**
 * @description TextFieldInput reusable component
 * @class TextFieldInput
 * @extends {React.Component<TextFieldInput.propTypes>}
 */
const TextFieldInput = React.forwardRef((props, ref) => {
	const {
		title,
		className,
		value,
		type,
		name,
		autoComplete,
		placeholder,
		style,
		onChange,
		disabled,
		containerClass,
		passwordViewableToggle,
		showPassword,
		onKeyDown
	} = props;

	const [localShowPassword, setLocalShowPassword] = React.useState(showPassword || false); // Initialize state

	const authInput = () => {
		return (
			<div className={clsx("custom-text-field", containerClass)}>
				{title && <span className="custom-text-field__label">{title}</span>}
				<div className={clsx("custom-text-field__input-container", { "custom-text-field__input-container--disabled": disabled })}>
					<input
						ref={ref}
						className={clsx("custom-text-field__input", className)}
						style={style}
						type={localShowPassword ? "text" : type || "text"} // Use state for showPassword
						name={name}
						placeholder={placeholder}
						value={value}
						onChange={onChange}
						autoComplete={autoComplete || "off"}
						disabled={disabled}
						onKeyDown={onKeyDown}
					/>
					{/* TODO: Fix password viewable toggle */}
					{/* {passwordViewableToggle && (
            <button
              className="custom-text-field__password-view-toggle"
              aria-label="toggle password visibility"
              onClick={() => setLocalShowPassword(prevState => !prevState)} // Toggle state
            >
              {localShowPassword ? <Icon.EyeOff color="#797979" /> : <Icon.Eye color="#797979" />}
            </button>
          )} */}
				</div>
			</div>
		);
	};

	const renderInput = () => (
		<div className={clsx("input-field", containerClass)}>
			{title && <span className="input-field__title">{title}</span>}
			<input
				ref={ref}
				style={style}
				type={type}
				className={clsx("input-field__input", className)}
				name={name}
				value={value}
				onChange={onChange}
				disabled={disabled}
				autoComplete={autoComplete}
				onKeyDown={onKeyDown}
				placeholder={placeholder}
			/>
		</div>
	);

	if (props.view === "auth") {
		return authInput();
	}

	return renderInput();
});

TextFieldInput.propTypes = {
	title: PropTypes.string,
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	autoComplete: PropTypes.string,
	onKeyDown: PropTypes.func,
	width: PropTypes.string,
	showPassword: PropTypes.bool,
	passwordViewableToggle: PropTypes.bool,
	containerClass: PropTypes.string,
	style: PropTypes.object,
	placeholder: PropTypes.string,
	view: PropTypes.string
};

TextFieldInput.defaultProps = {
	type: "text",
	showPassword: false
};

export default TextFieldInput;
