import WComponent from "../WComponent";
import DatePicker from "react-datepicker";
import clsx from "clsx";
import "react-datepicker/dist/react-datepicker.css";
import "./date-selector.css";

class DateSelector extends WComponent {
	render() {
		const {
			containerClass,
			className,
			title,
			selectedDate,
			onDateSelect,
			dateFormat = "MMMM d, yyyy",
			timeFormat = "HH:mm",
			showTimeSelect = false,
			timeIntervals = 15
		} = this.props;

		return (
			<div className={clsx("date-selector", containerClass)}>
				<div className="date-selector__title">{title}</div>
				<DatePicker
					className={clsx("date-selector__input", className)}
					selected={selectedDate}
					onChange={onDateSelect}
					timeFormat={timeFormat}
					timeIntervals={timeIntervals}
					dateFormat={dateFormat}
					timeCaption="time"
					autoComplete="off"
					showTimeSelect={showTimeSelect}
					showYearDropdown
					showMonthDropdown
				/>
			</div>
		);
	}
}

export default DateSelector;
