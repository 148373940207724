// External Libraries
import React from "react";
import * as Icon from "react-feather";
import _ from "lodash";
import clsx from "clsx"; // Add this import at the top of the file

// Constants
import CLIENTS from "../../constants/Clients";

// Services
import EngagementService from "../../services/EngagementService";
import ClientService from "../../services/ClientService";
import UserService from "../../services/UserService";

// Common Components
import WComponent from "../common/WComponent";
import Toggle from "../common/toggle/Toggle";
import SearchInput from "../common/search_input/SearchInput";
import SearchableDropdown from "../common/searchable_dropdown/SearchableDropdown";
import Loader from "../common/loader/Loader";

// Stlying
import "../common/dropdown/dropdown.css";
import "./client-summary.css";
import PageHeading from "../common/page_heading/PageHeading";
import Tabs from "../common/tabs/Tabs";
import Tab from "../common/tabs/tab/Tab";
import Button from "../common/button/Button";

class ClientSummary extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			summary: [],
			staff: [],
			partners: [],
			bookkeepers: [],

			search: "",
			errors: {},

			partnerId: 0,
			staffId: 0,
			assignedUserId: 0,
			bookkeeperId: 0,
			type: CLIENTS.clientTypes.corporate.id,
			partnerName: "",

			clientView: "corporate",

			title: props.title,

			refresh: true,

			loading: true,
			limit: 50,
			offset: 0,
			loadedAll: false,

			sortField: null,
			sortOrder: "desc"
		};

		this.fetchSummaryDebounce = _.debounce(this.fetchSummary, 500, {
			leading: true,
			trailing: true
		});
	}

	componentDidMount = async () => {
		await this.resetComponent();
	};

	resetComponent = async () => {
		await this.fetchFlows();
		await this.fetchSummary({});
	};

	fetchFlows = async () => {
		let flows = await EngagementService.fetchFlows({});

		await this.update({ flows });
	};

	onSearch = async event => {
		await this.update({
			search: event.target.value
		});
		this.fetchSummaryDebounce();
	};

	onLoadMore = async () => {
		let { limit } = this.state;
		await this.update({
			limit: limit + 50
		});
		this.fetchSummary({ showLoading: false });
	};

	onPartnerSelect = async item => {
		await this.update({
			partnerId: item.id
		});
		await this.fetchSummary({});
	};

	onStaffSelect = async item => {
		await this.update({ assignedUserId: item.id });
		await this.fetchSummary({});
	};

	onBookkeeperSelect = async item => {
		await this.update({ bookkeeperId: item.id });
		await this.fetchSummary({});
	};

	onTypeSelect = async type => {
		console.log("type", type.id);

		let typeIdOutcome = type.id == "corporate" ? CLIENTS.clientTypes.corporate.id : CLIENTS.clientTypes.personal.id;

		await this.update({ type: typeIdOutcome });
		await this.fetchSummary({});
	};

	fetchSummary = async ({ showLoading = true }) => {
		let { limit, offset, partnerId, assignedUserId, bookkeeperId, type, search } = this.state;

		console.log("partnerId", partnerId);
		console.log("assignedUserId", assignedUserId);

		await this.update({
			loading: true && showLoading
		});

		let summary = await ClientService.fetchClientSummary({ limit, offset, partnerId, assignedUserId, bookkeeperId, type, search });

		if (!summary) {
			await this.update({
				loading: false && showLoading
			});

			return;
		}

		await this.update({
			loading: false && showLoading,
			loadedAll: summary.length < limit,
			summary
		});
	};

	fetchUsers = async () => {
		let users = await UserService.fetchUsers({ groupId: 0, defaultValue: "All Assigned" });
		let bookkeepers = await UserService.fetchUsers({ groupId: 0, defaultValue: "All Bookkeepers" });

		await this.update({
			staff: users,
			bookkeepers
		});
	};

	sortBy = async field => {
		let { sortOrder } = this.state;

		await this.update({
			sortField: field,
			sortOrder: sortOrder === "desc" ? "asc" : "desc"
		});
		this.fetchSummary({ showLoading: false });
	};

	renderSortIcon = field => {
		let { sortField, sortOrder } = this.state;

		return sortField === field && (sortOrder === "desc" ? <Icon.ChevronUp size={14} /> : <Icon.ChevronDown size={14} />);
	};

	renderEngagements = (engagements, flow) => {
		let list = engagements.filter(e => {
			return e.flow_id === flow.id;
		});

		return list.map(e => {
			// Get the flow state of the engagement
			let [flowState] = flow.flow_states.filter(flowState => {
				return flowState.id === e.flow_state_id && flowState.status !== "inactive";
			});

			if (!flowState) {
				return undefined;
			}

			return (
				<div
					key={e.id}
					className="s-list-eng table__item__label"
					style={{ backgroundColor: flowState.color }}
					onClick={() => this.props.history.push(`/engagements/${e.id}`)}
				>
					{flow.name} - {flowState.name}
					{/* {type === WORKFLOW.workflowTypes.t1.key ? e.fiscal_year_end.substring(0, 4) : e.fiscal_year_end} */}
				</div>
			);
		});
	};

	render() {
		let { loading, search, loadedAll, summary, type, flows } = this.state;
		let { history } = this.props;
		let { onPartnerSelect, onStaffSelect, onBookkeeperSelect, onSearch, onTypeSelect, renderEngagements, onLoadMore } = this;

		return (
			<div className="c-s">
				<PageHeading section="Workflow" title={"Client Summary"} />
				<div className="c-s__header">
					<Tabs onSelect={onTypeSelect} selected={type}>
						<Tab id={"corporate"} icon={<Icon.Briefcase size={18} strokeWidth={1.5} />} text="Corporate" />
						<Tab id={"personal"} icon={<Icon.User size={18} strokeWidth={1.5} />} text="Personal" />
					</Tabs>

					<div className="c-s__header__right">
						<SearchableDropdown title="Partner" type="partners" onSelect={onPartnerSelect} showAll selectedName={"All Partners"} />

						<SearchableDropdown title="Staff" type="staff" onSelect={onStaffSelect} showAll selectedName={"All Staff"} />

						<SearchableDropdown title="Bookeeper" type="bookeeper" onSelect={onBookkeeperSelect} showAll selectedName={"All Bookeepers"} />

						<SearchInput title="Search" onChange={onSearch} value={search} />
					</div>
				</div>

				{loading && (
					<div className="container-loader">
						<Loader />
					</div>
				)}

				{summary.length === 0 && !loading && (
					<div className="container-empty">
						<div>
							<Icon.Frown size={64} />
						</div>
						<div>... no summary ...</div>
					</div>
				)}

				{summary.length > 0 && !loading && (
					<div className="scroll-container">
						<div className="t">
							{/* TABLE HEADER */}
							<div className="t__header">
								<div className={clsx("t__header__item", "c-s__header__item--name")}>Client</div>

								{flows.map(flow => {
									return (
										<div key={flow.id} className="t__header__item">
											{flow.name}
										</div>
									);
								})}
							</div>

							{/* TABLE ITEMS */}
							{summary.map((client, index) => {
								return (
									<div
										className={clsx("c-s-row", {
											"t__row--stripe": index % 2 === 0
										})}
										key={index}
									>
										<div
											className={clsx("t__row__item", "c-s__row__item--name")}
											onClick={() => history.push(`/clients/${type}/${client.id}`)}
										>
											{client.name}
										</div>
										{flows.map(flow => {
											return (
												<div key={flow.id} className="t__col__item">
													{renderEngagements(client.engagements, flow)}
												</div>
											);
										})}
									</div>
								);
							})}
							{!loadedAll && (
								<div className="c-s__footer">
									<Button onClick={onLoadMore} text="Load More"></Button>
								</div>
							)}
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default ClientSummary;
