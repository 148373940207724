import React from "react";
import * as Icon from "react-feather";
import "./add-item-button.css";

const AddItemButton = ({ itemName, onClick }) => {
	return (
		<div className="add-item-btn" onClick={onClick}>
			<div className="add-item-btn__title">Add {itemName}</div>
			<Icon.PlusCircle className="add-item-btn__icon" size={28} color="var(--company-color)" />
		</div>
	);
};

export default AddItemButton;
