import React from "react";
import { Redirect } from "react-router-dom";
import * as Icon from "react-feather";

import UserService from "../../../services/UserService";

import "./fields.css";
import CustomFieldService from "../../../services/CustomFieldService";
import WComponent from "../../common/WComponent";
import PageHeading from "../../common/page_heading/PageHeading";

class Fields extends WComponent {
	constructor() {
		super();

		this.state = {
			loading: false,

			fields: [],
			showMore: null
		};
	}

	async componentDidMount() {
		await this.resetComponent();
	}

	componentDidUpdate() {}

	async resetComponent() {
		await this.fetchFields();
	}

	fetchFields = async () => {
		let fields = await CustomFieldService.fetchFields();

		await this.update({
			fields
		});
	};

	onChange(e) {
		this.update({ [e.target.name]: e.target.value });
	}

	onShowMore = async fieldId => {
		let { showMore } = this.state;

		showMore = showMore === fieldId ? null : fieldId;

		await this.update({
			showMore
		});
	};

	render() {
		let { fields } = this.state;

		let userData = UserService.getUserData();

		if (userData.groupId === 2) {
			return <Redirect to={"/dashboard"} />;
		}

		return (
			<div className="container">
				<PageHeading section="Settings" title={"Fields"} />

				<div className="fields">
					{fields.map((f, index) => {
						return (
							<div className="fields__item" key={`${index}-${f.name}`} onClick={() => this.onShowMore(f.id)}>
								<div className="fields__item__details">
									<div className="fields__item__details__item">Name: {f.name}</div>
									<div className="fields__item__details__item">Description: {f.description}</div>
									<div className="fields__item__details__item">Field: {f.field}</div>
									<div className="fields__item__details__item">Type: {f.type}</div>
									<div className="fields__item__details__actions">
										<Icon.ChevronDown />
									</div>
								</div>

								{/* {showMore === f.id && this.renderStates(f)} */}
							</div>
						);
					})}
				</div>
			</div>
		);
	}
}

export default Fields;
