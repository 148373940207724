import APIDoc from "../api/api";
import Kichiri from "react-kichiri";
import ConfigurationService from "./ConfigurationService.js";

const kichiriService = new Kichiri(APIDoc, ConfigurationService.resolveApi().rest, false).api;

function checkIfUnauthorizedThenLogout(error) {
	if (error.response && error.response.status === 401) {
		if (window.location.pathname !== "/login") {
			window.location.href = "/login";
		}
		localStorage.removeItem("workflow_user");
	}
}

kichiriService.addErrorInterceptor(checkIfUnauthorizedThenLogout);

export default kichiriService;
