// External Imports
import React from "react";
import Modal from "react-responsive-modal";
import * as Icon from "react-feather";

// Services

// Commmon Components
import WComponent from "../../common/WComponent";
import DateRangeFilter from "../../workflow/EngagmentFilterModal/DateRangeFilter";
import SearchableDropdown from "../../common/searchable_dropdown/SearchableDropdown";

// Constants
import WORKFLOW from "../../../constants/Workflow";
import { STATUSES } from "../../../constants/Tasks";
import UserAssigner from "../../common/user_assigner/UserAssigner";
import Button from "../../common/button/Button";

// Styling
import "./task-filter-modal.css";
import ClientAssigner from "../../common/client_assigner/ClientAssigner";
import ClientGroupAssigner from "../../common/client_group_assigner/ClientGroupAssigner";

export default class TaskFilterModal extends WComponent {
	constructor(props) {
		super(props);

		let statuses = Object.values(STATUSES);

		this.state = {
			show: false,

			menuItems: ["Dates", "Users", "Status", "Clients", "Client Groups"],
			selectedMenuItem: "Dates",

			statuses,

			engagementId: 0,
			clientId: 0,

			assignedUsers: [],
			assignedStatuses: [],

			dueDate: WORKFLOW.defaultDateFunction,

			selectedColumns: {},
			assignedClients: [],
			assignedClientGroups: []
		};
	}

	componentDidMount() {
		this.resetComponent();
	}

	componentDidUpdate(prevProps) {
		let { show, filters } = this.props;

		let filtersChanges = JSON.stringify(prevProps.filters) !== JSON.stringify(filters);

		if (prevProps.show !== show || filtersChanges) {
			this.resetComponent();
		}
	}

	resetComponent = async () => {
		let { show, filters } = this.props;

		let { clientId, engagementId, assignedUsers, assignedStatuses, dueDate, columns } = filters;

		// Currently the task views do not support assignedUsers, so for backwards compatibility we need to stub this field
		if (!assignedUsers) {
			assignedUsers = [];
		}

		await this.update({
			show,

			clientId,
			engagementId,
			assignedUsers,
			assignedStatuses,
			dueDate,

			selectedColumns: columns || {}
		});
	};

	onClose = async () => {
		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	onApply = () => {
		let { clientId, engagementId, assignedUsers, assignedStatuses, assignedClients, assignedClientGroups, dueDate, selectedColumns } = this.state;
		let { onFilterChange } = this.props;

		if (onFilterChange) {
			onFilterChange({
				filters: { clientId, engagementId, assignedUsers, assignedStatuses, assignedClients, assignedClientGroups, dueDate },
				columns: selectedColumns
			});
		}

		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	onStatusSelect = selectedStatus => {
		let { assignedStatuses } = this.state;

		let [exists] = assignedStatuses.filter(status => {
			return status === selectedStatus.id;
		});

		if (!exists) {
			assignedStatuses.push(selectedStatus.id);
		}

		this.update({
			assignedStatuses
		});
	};

	onStatusRemove = async ({ removeStatus }) => {
		let { assignedStatuses } = this.state;

		let index = assignedStatuses.indexOf(removeStatus);

		assignedStatuses.splice(index, 1);
		let newAssignedStatuses = assignedStatuses.slice();

		await this.update({
			assignedStatuses: newAssignedStatuses
		});
	};

	onSelectAllStatuses = async () => {
		let { statuses } = this.state;

		let newAssignedStatuses = [];

		for (let status of statuses) {
			newAssignedStatuses.push(status.id);
		}

		await this.update({
			assignedStatuses: newAssignedStatuses
		});
	};

	getStatus = id => {
		let { statuses } = this.state;

		return statuses.find(s => s.id === id);
	};

	renderStatuses() {
		let { assignedStatuses } = this.state;

		return (
			<>
				<div className="t-f-m__body__search">
					<SearchableDropdown
						containerClass="t-f-m__body__s-d-container"
						title="Status"
						onSelect={this.onStatusSelect}
						showAll
						type={"taskStatuses"}
						clearOnSelect
					/>
					<Button view="secondary" className="t-f-m__body__select-all-btn" onClick={this.onSelectAllStatuses} text="Select All" />
				</div>
				<div className="assigned-flow-state">
					<div className="assigned-flow-state__title">Current Filters:</div>
					{assignedStatuses.map(statusId => {
						let status = this.getStatus(statusId);

						let statusStyles = {};
						statusStyles.backgroundColor = status.color;

						return (
							<div key={status.id} className="assigned-flow-state__item" style={statusStyles}>
								<div>{status.name}</div>

								<Icon.XCircle size={28} color="var(--primary-main)" onClick={() => this.onStatusRemove({ removeStatus: statusId })} />
							</div>
						);
					})}
				</div>
			</>
		);
	}

	onDueDateSelect = async ({ dateFunction, payload }) => {
		let newDueDate = {
			dateFunction,
			payload
		};

		await this.update({
			dueDate: newDueDate
		});
	};

	renderDates() {
		let { dueDate } = this.state;

		return (
			<>
				<div className="engagement-filter-modal__label">Due Date</div>
				<DateRangeFilter dateFunction={dueDate} onSelect={this.onDueDateSelect} />
			</>
		);
	}

	onAssignedUsersChanged = async ({ selectedUsers }) => {
		await this.update({
			assignedUsers: selectedUsers
		});

		// if (this.props.onFilterChange) {
		// 	this.props.onFilterChange({ filters: { assignedUsers } });
		// }
	};

	renderUsers() {
		let { assignedUsers } = this.state;

		return <UserAssigner onChange={this.onAssignedUsersChanged} hideRolePicker users={assignedUsers} />;
	}

	onColumnSelected = async ({ column }) => {
		let { selectedColumns } = this.state;

		if (typeof selectedColumns[column.id] !== "undefined") {
			delete selectedColumns[column.id];
		} else {
			selectedColumns[column.id] = true;
		}

		await this.update({
			selectedColumns
		});
	};

	onAssignedClientGroupsChanged = async ({ selectedClientGroups }) => {
		await this.update({
			assignedClientGroups: selectedClientGroups
		});
	};

	renderClientGroups = () => {
		let { assignedClientGroups } = this.state;

		return <ClientGroupAssigner onChange={this.onAssignedClientGroupsChanged} clientGroups={assignedClientGroups} />;
	};

	onAssignedClientsChanged = async ({ selectedClients }) => {
		await this.update({
			assignedClients: selectedClients
		});
	};

	renderClients = () => {
		let { assignedClients } = this.state;

		return <ClientAssigner onChange={this.onAssignedClientsChanged} clients={assignedClients} />;
	};

	onMenuItemClicked = ({ menuItem }) => {
		this.update({
			selectedMenuItem: menuItem
		});
	};

	render() {
		let { show, selectedMenuItem, menuItems } = this.state;
		let { onClose } = this.props;

		return (
			<Modal
				open={show}
				onClose={onClose}
				center
				classNames={{
					modal: "engagement-filter-modal__modal"
				}}
			>
				<div>
					<div className="modal-title">Filters</div>

					<div className="engagement-filter-modal">
						<div className="engagement-filter-modal__menu">
							{menuItems.map(menuItem => {
								return (
									<div
										onClick={() => this.onMenuItemClicked({ menuItem })}
										className={`engagement-filter-modal__menu__item ${
											selectedMenuItem === menuItem ? "engagement-filter-modal__menu__item--selected" : ""
										}`}
									>
										{menuItem}
									</div>
								);
							})}
						</div>
						<div className="engagement-filter-modal__body t-f-m__body">
							{selectedMenuItem === "Dates" && this.renderDates()}
							{selectedMenuItem === "Users" && this.renderUsers()}
							{selectedMenuItem === "Status" && this.renderStatuses()}
							{selectedMenuItem === "Clients" && this.renderClients()}
							{selectedMenuItem === "Client Groups" && this.renderClientGroups()}
						</div>
					</div>
				</div>
				<div className="modal-footer">
					<Button view="secondary" onClick={onClose} text="Cancel" />
					<Button onClick={this.onApply} text="Apply" />
				</div>
			</Modal>
		);
	}
}
