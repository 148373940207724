import moment from "moment";
import { FREQUENCIES, RRULE_MONTH_WEEK } from "../constants/Schedules";

const ScheduleService = {
	/**
	 * Searches through different RRule Frequencies
	 *
	 * @param {String} searchTerm
	 */
	async searchRRuleFrequencies({ searchTerm }) {
		let frequenciesArray = Object.values(FREQUENCIES);

		let frequencies = frequenciesArray.filter(frequency => {
			let frequencyName = frequency.name.toLowerCase();
			let isNameMatch = frequencyName.indexOf(searchTerm) !== -1;

			return isNameMatch;
		});

		return frequencies;
	},

	/**
	 * Searches through all possible 31 days in a month. Note: Doesn't actually perform the search, will always returned the full list
	 *
	 * @param {String} searchTerm
	 * @returns {Array}
	 */
	async searchDays({ searchTerm }) {
		let days = Array.from({ length: 31 }, (_, i) => i + 1);
		let monthDays = days.map(day => ({ id: day, name: day }));

		return monthDays;
	},

	/**
	 *
	 *
	 * @param {String} searchTerm
	 * @returns {Array}
	 */
	async searchMonthWeeks({ searchTerm }) {
		return Object.values(RRULE_MONTH_WEEK);
	},

	convertDateToRRuleFormat({ date }) {
		return (
			moment(date)
				.utc()
				.format("YYYYMMDDTHHmmss") + "Z"
		);
	}
};

export default ScheduleService;
