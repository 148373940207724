import React from "react";
import Modal from "react-responsive-modal";
import Button from "../../button/Button";
import WComponent from "../../WComponent";

class ConfirmationModal extends WComponent {
	handleClose = ({ confirmed = false, proxyState = this.props.proxyState } = {}) => {
		let { onClose } = this.props;

		onClose({ confirmed, proxyState });
	};

	render() {
		const { show, title, warning } = this.props;
		const { handleClose } = this;

		return (
			<Modal open={show} onClose={() => handleClose()} center>
				<div className="modal-title">{title}</div>
				<div className="modal-content">{warning}</div>
				<div className="modal-footer">
					<Button view="secondary" text="No" onClick={() => handleClose({ confirmed: false })} />
					<Button text="Yes" onClick={() => handleClose({ confirmed: true })} />
				</div>
			</Modal>
		);
	}
}

export default ConfirmationModal;
