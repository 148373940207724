import Kichiri from "./KichiriService";
import UserService from "./UserService";

const StaffService = {
	/**
	 * Create a staff account
	 * @param {String} firstName
	 * @param {String} lastName
	 * @param {String} title
	 * @param {String} email
	 * @param {String} phone
	 * @param {String} password
	 * @returns {Object}
	 */
	async create({ firstName, lastName, title, email, phone, password, defaultRoleId }) {
		let userData = UserService.getUserData();
		try {
			let response = await Kichiri.user.create(
				{
					companyId: userData.companyId,
					firstName,
					lastName,
					title,
					email,
					phone,
					password,
					defaultRoleId
				},
				{},
				userData.authToken
			);
			return response;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Update a staff account
	 * @param {Number} userId
	 * @param {String} firstName
	 * @param {String} lastName
	 * @param {String} title
	 * @param {String} email
	 * @param {String} phone
	 * @param {String} password
	 * @param {String} status
	 * @param {String} defaultRole
	 * @returns {Object}
	 */
	async update({ userId, firstName, lastName, title, email, phone, password, status, defaultRoleId }) {
		let userData = UserService.getUserData();

		try {
			let response = await Kichiri.user.update(
				{ userId, firstName, lastName, title, email, phone, password, status, defaultRoleId },
				{},
				userData.authToken
			);
			return response;
		} catch (error) {
			console.log(error);
		}
	}
};

export default StaffService;
