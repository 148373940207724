import React from "react";
import * as Icon from "react-feather";
import "./engagement-task-card.css";

class EngagementTaskCard extends React.Component {
	render() {
		const { task, index, onRemoveTask, onUpdateEngTask } = this.props;

		return (
			<div key={index} className="eng-tasks">
				<div
					className="eng-task-card"
					onClick={() => {
						onUpdateEngTask(index);
					}}
				>
					<div className="eng-task-card__content">
						<div className="eng-task-card__content__assigned">
							<Icon.User size={14} color="var(--primary-main)" />
							<span className="eng-task-card__content__assigned__value"> {task.assignedUser.name}</span>
						</div>
						<div className="eng-task-card__content__divider" />
						<span className="eng-task-card__content__title">{task.name}</span>
					</div>

					<Icon.XCircle
						className="eng-task-card__remove-task-icon"
						size={28}
						color="#FF6B6B"
						onClick={e => {
							e.stopPropagation();
							onRemoveTask(index);
						}}
					/>
				</div>
			</div>
		);
	}
}

export default EngagementTaskCard;
