import UserService from "./UserService";

import moment from "moment";
import ConfigurationService from "./ConfigurationService";
import ClientService from "./ClientService";

const ReconciliationService = {
	/**
	 * Filters an array of objects based on a date range.
	 *
	 * @param {Array} reconciliationData - The array to filter.
	 * @param {Date} endDate - The end date of the range.
	 * @param {Date} startDate - The start date of the range.
	 * @returns {Array} The filtered array.
	 */
	async recalcObjWithinDateRange({ reconciliationData, endDate, startDate }) {
		let currentYearObj = reconciliationData.filter(item => {
			if (!item.hasOwnProperty("date")) item.date = "";
			if (!item.hasOwnProperty("description")) item.description = "";
			if (!item.hasOwnProperty("amount")) item.amount = "";

			let itemDate = item.date ? new Date(item.date) : null;
			let itemDate2 = item.date2 ? new Date(item.date2) : null;
			return (itemDate >= startDate && itemDate <= endDate) || (itemDate2 && itemDate2 >= startDate && itemDate2 <= endDate);
		});

		return currentYearObj;
	},

	/**
	 * Calculates the total interest from an array of objects.
	 *
	 * @param {Array} obj - The array to calculate interest from.
	 * @returns {Number} The total interest.
	 */
	async calculateInterest({ obj }) {
		let interestObj = obj.filter(item => item.description.toLowerCase().includes("interest") || item.description.toLowerCase().includes("penalty"));

		let totalInterest = this.sumValues(interestObj);

		return totalInterest;
	},

	/**
	 * Sums the 'amount' property of an array of objects.
	 *
	 * @param {Array} interestObj - The array to sum.
	 * @returns {Number} The total sum.
	 */
	async sumValues(interestObj) {
		let totalSum = interestObj.reduce((accum, obj) => {
			return accum + obj.amount;
		}, 0);

		return totalSum;
	},

	/**
	 * Calculates the total instalments from an array of objects.
	 *
	 * @param {Array} obj - The array to calculate instalments from.
	 * @returns {Number} The total instalments.
	 */
	async calculateInstalment({ obj }) {
		let interestObj = obj.filter(item => item.description.toLowerCase().includes("payment") || item.description.toLowerCase().includes("commercial taxes"));

		let totalInstalments = this.sumValues(interestObj);

		return totalInstalments;
	},

	/**
	 * Calculates the total interest income from an array of objects.
	 *
	 * @param {Array} obj - The array to calculate interest income from.
	 * @returns {Number} The total interest income.
	 */
	async calculateInterestIncome({ obj }) {
		let interestIncome = obj.filter(item => item.description.toLowerCase().includes("interest received"));

		let totalInterest = this.sumValues(interestIncome);

		return totalInterest;
	},

	/**
	 * Creates a reconciliation object based on a given date.
	 *
	 * @param {Object} obj - The initial object to use for calculations.
	 * @param {Date} date - The date to use for the reconciliation.
	 * @returns {Object} The reconciliation object, which includes various calculated values.
	 */
	async filterReconciliationData({ reconciliationData, yearEndDate }) {
		let openingBal = 0;
		let craBal = 0;

		const yearEnd = moment(yearEndDate, "MM/DD/YYYY");
		let startDate = yearEnd
			.clone()
			.subtract(11, "months")
			.startOf("month");
		let prior2ndYear = startDate
			.clone()
			.subtract(12, "months")
			.startOf("month");
		let priorYear = startDate.year();
		let currentYear = yearEnd.year();
		let month = moment(yearEnd).format("MMM");
		let day = yearEnd.date();

		let cyObj = await this.recalcObjWithinDateRange({ reconciliationData, endDate: yearEnd, startDate });
		let cyInterestPaid = await this.calculateInterest({ obj: cyObj });
		let cyInstalment = await this.calculateInstalment({ obj: cyObj });
		let cyInterestIncome = await this.calculateInterestIncome({ obj: cyObj });

		// let pyObj = await this.recalcObjWithinDateRange({ reconciliationData, endDate: startDate, startDate: prior2ndYear });
		// let pyInterestPaid = await this.calculateInterest({ obj: pyObj });
		// let pyInstalment = await this.calculateInstalment({ obj: pyObj });
		// let pyInterestIncome = await this.calculateInterestIncome({ obj: pyObj });

		let pyObj = await this.recalcObjWithinDateRange({ reconciliationData, endDate: startDate, startDate: prior2ndYear });
		let pyInterestPaid = 0;
		let pyInstalment = 0;
		let pyInterestIncome = 0;

		let objData = {
			yearEnd,
			startDate,
			priorYear,
			currentYear,
			month,
			day,
			cyObj,
			openingBal,
			craBal,
			cyInterestPaid,
			cyInstalment,
			cyInterestIncome,
			pyInterestIncome,
			pyInstalment,
			pyInterestPaid
		};

		return objData;
	},

	/**
	 * Upload files and reconcile them for the CRA and GL for a client
	 *
	 * @param {Array<File>} files
	 * @returns {Object}
	 */
	async reconcileFiles({ files }) {
		try {
			let { authToken } = await UserService.getUserData();

			let formData = new FormData();

			for (let file of files) {
				formData.append("files", file, file.name);
			}

			let response = await fetch(`${ConfigurationService.resolveApi().rest}/tools/reconciliation/upload`, {
				method: "POST",
				body: formData,
				headers: {
					// "Content-Type": 'multipart/form-data',
					authorization: authToken
				}
			});

			if (response.status !== 200) {
				return { response: null, error: "Error occurred trying to reconcile files." };
			}

			let data = await response.json();

			return { response: data, error: null };
		} catch (error) {
			return { response: null, error };
		}
	},

	/**
	 * Downloads a summary based on the reconciliation for a client's CRA records and GL
	 *
	 * @param {Object} filteredReconciliationData
	 * @param {Object} inputParams
	 * @param {Number} clientId
	 */
	async downloadSummary({ filteredReconciliationData, inputParams, clientId }) {
		let userData = UserService.getUserData();

		try {
			let { client } = await ClientService.fetchClient({ clientId });

			let response = await fetch(
				`${ConfigurationService.resolveApi().rest}/tools/reconciliation/download`,
				{
					method: "POST",
					body: JSON.stringify({
						excelData: filteredReconciliationData,
						adjustments: inputParams,
						clientId
					}),
					headers: {
						authorization: userData.authToken,
						Accept: "application/json",
						"Content-Type": "application/json"
					}
				},
				10000
			);

			let blob = await response.blob();

			// Tis a ghetto little thing
			let url = window.URL.createObjectURL(blob);
			let a = document.createElement("a");
			a.href = url;
			a.target = "_blank";
			a.download = `${client.name} - Corporation tax - continuity.xlsx`;
			document.body.appendChild(a);
			a.click();
			a.remove();
		} catch (error) {}
	},

	/**
	 * Downloads GST reconciliation summaries for a specified client.
	 *
	 * This asynchronous function first retrieves the current user's data, then fetches the specified client's
	 * information using the client ID. It makes a POST request to a specific endpoint to initiate the download
	 * of GST reconciliation summaries. The response is expected to be a blob, which is then used along with a
	 * dynamically generated filename to initiate a file download through the `downloadFile` method.
	 *
	 * @param {Object} params - The parameters object containing the client ID.
	 * @param {number} params.clientId - The ID of the client for whom the GST summaries are to be downloaded.
	 */
	async downloadGSTSummaries({ clientId, endDate }) {
		let userData = UserService.getUserData();

		try {
			let { client } = await ClientService.fetchClient({ clientId });

			let response = await fetch(`${ConfigurationService.resolveApi().rest}/tools/hstReconciliation/download`, {
				method: "POST",
				body: JSON.stringify({
					clientId,
					endDate
				}),
				headers: {
					authorization: userData.authToken,
					Accept: "application/json",
					"Content-Type": "application/json"
				}
			});

			let blob = await response.blob();

			this.downloadFile({ blob, fileName: `${client.name} - GST Reconciliation.xlsx` });
		} catch (error) {}
	},

	/**
	 * Downloads GST summaries using the Quick Method for a specified client.
	 *
	 * This asynchronous function retrieves user data, fetches client information based on the provided client ID,
	 * and then initiates a download of the GST summaries in the form of an Excel file. The file is downloaded
	 * by making a POST request to a specific endpoint with the client ID. The response is expected to be a blob,
	 * which is then passed along with a dynamically generated filename to a `downloadFile` method to handle the actual download.
	 *
	 * @param {Object} params - The parameters object.
	 * @param {number} params.clientId - The ID of the client for whom the GST summaries are to be downloaded.
	 */
	async downloadGSTSummariesQuickMethod({ clientId, endDate }) {
		let userData = UserService.getUserData();

		try {
			let { client } = await ClientService.fetchClient({ clientId });

			let response = await fetch(`${ConfigurationService.resolveApi().rest}/tools/hstReconciliation/downloadQuickMethod`, {
				method: "POST",
				body: JSON.stringify({
					clientId,
					endDate
				}),
				headers: {
					authorization: userData.authToken,
					Accept: "application/json",
					"Content-Type": "application/json"
				}
			});

			let blob = await response.blob();

			this.downloadFile({ blob, fileName: `${client.name} - GST Reconciliation(Quick Method).xlsx` });
		} catch (error) {}
	},

	/**
	 * Initiates a download of a file represented by a blob.
	 *
	 * This function creates a temporary anchor (`<a>`) element, sets its `href` attribute to an object URL representing the blob,
	 * and sets the `download` attribute to the provided filename. It then programmatically clicks the anchor to trigger the download.
	 * After the download is initiated, the function cleans up by removing the anchor from the document and revoking the object URL to release memory.
	 *
	 * @param {Object} params - The parameters object.
	 * @param {Blob} params.blob - The blob representing the file to be downloaded.
	 * @param {string} params.fileName - The name of the file to be downloaded.
	 */
	downloadFile({ blob, fileName }) {
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement("a");

		a.href = url;
		a.target = "_blank";
		a.download = fileName;
		document.body.appendChild(a);
		a.click();
		a.remove();

		// Clean up the URL object
		window.URL.revokeObjectURL(url);
	}
};

export default ReconciliationService;
