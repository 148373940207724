// External Libraries
import React from "react";
import clsx from "clsx";

// Components
import WComponent from "../WComponent";

// Styling
import "./button.css";

/**
 * Views: primary, secondary, icon
 */

class Button extends WComponent {
	render() {
		const { onClick, className, text, compact, view = "primary", icon, disabled } = this.props;

		return (
			<div
				className={clsx("button", className, {
					"button--compact": compact,
					"button--disabled": disabled,
					"button--primary": view === "primary",
					"button--secondary": view === "secondary"
				})}
				onClick={onClick}
				disabled={disabled}
			>
				{text && text}
				{icon && icon}
			</div>
		);
	}
}

export default Button;
