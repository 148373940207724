import React from "react";
import { Link, Redirect } from "react-router-dom";
import UserService from "../../services/UserService";
import "./navbar.css";
import SupportTicketModal from "./SupportTicketModal/SupportTicketModal";
import AuthService from "../../services/AuthService";
import WComponent from "../common/WComponent";
import * as Icon from "react-feather";
import NavbarLink from "../common/navbar_link/NavbarLink";
import CompanyService from "../../services/CompanyService";

class Navbar extends WComponent {
	constructor() {
		super();
		this.state = {
			loading: false,
			flows: [],
			showTicketModal: false,
			currentActiveTab: "Client Summary",
			logoSrc: ""
		};
	}

	componentDidMount = async () => {
		let currentPath = window.location.pathname;
		this.setActiveTab(currentPath); // Set active tab on mount

		// Listen for browser navigation (back/forward)
		window.addEventListener("popstate", this.handlePathChange);

		await this.fetchLogo();
	};

	componentWillUnmount() {
		// Clean up the listener when the component unmounts
		window.removeEventListener("popstate", this.handlePathChange);
	}

	signOut = async () => {
		this.update({
			loading: true
		});

		try {
			await AuthService.logout();
		} catch (error) {
			console.log(error);
		}

		this.update({
			loading: false
		});
	};

	handlePathChange = () => {
		let currentPath = window.location.pathname;
		this.setActiveTab(currentPath); // Update active tab on path change
	};

	setActiveTab = currentPath => {
		let activeTab = "Client Summary";

		if (currentPath.includes("/clients/corporate")) {
			activeTab = "Corporate";
		} else if (currentPath.includes("/clients/personal")) {
			activeTab = "Personal";
		} else if (currentPath.includes("/clients/trust")) {
			activeTab = "Trusts";
		} else if (currentPath.includes("/clients/groups")) {
			activeTab = "Groups";
		} else if (currentPath.includes("/engagements")) {
			activeTab = "Engagements";
		} else if (currentPath.includes("/tasks")) {
			activeTab = "Tasks";
		}

		this.setState({
			currentActiveTab: activeTab
		});
	};

	onSubmitATicket = () => {
		this.update({
			showTicketModal: true
		});
	};

	onSupportTicketModalClose = () => {
		this.update({
			showTicketModal: false
		});
	};

	isActiveTab = name => {
		let { currentActiveTab } = this.state;

		return name === currentActiveTab ? "navbar-link--active" : "";
	};

	handleTabClick = name => {
		this.update({
			currentActiveTab: name
		});
	};

	async fetchLogo() {
		this.update({
			loading: true
		});
		try {
			let response = await CompanyService.getLogo();
			const imageObjectUrl = URL.createObjectURL(response);
			this.update({ logoSrc: imageObjectUrl });
		} catch (error) {
			console.log(error);
		}
		this.update({
			loading: false
		});
	}

	render() {
		let { showTicketModal, logoSrc } = this.state;

		let { isActiveTab } = this;

		let userData = UserService.getUserData();

		if (!userData) {
			return <Redirect to={"/login"} />;
		}

		let protectedNavLinks = null;
		let administratorNavLinks = [
			{ to: "/settings/users", label: "Users" },
			{ to: "/settings/templates", label: "Templates" },
			{ to: "/settings/flows", label: "Flows" },
			{ to: "/settings/fields", label: "Fields" },
			{ to: "/settings/company", label: "Company" }
		];

		let managerNavLinks = [
			{ to: "/settings/users", label: "Users" },
			{ to: "/settings/templates", label: "Templates" },
			{ to: "/settings/flows", label: "Flows" },
			{ to: "/settings/fields", label: "Fields" }
		];
		if (UserService.isAdministrator()) {
			protectedNavLinks = (
				<>
					{administratorNavLinks.map(link => {
						return (
							<NavbarLink
								label={link.label}
								value={link.label}
								to={link.to}
								icon={<Icon.Settings size={18} />}
								isActiveTab={isActiveTab}
								handleTabClick={this.handleTabClick}
							/>
						);
					})}
				</>
			);
		} else if (UserService.isManager()) {
			protectedNavLinks = (
				<>
					{managerNavLinks.map(link => {
						return (
							<NavbarLink
								label={link.label}
								value={link.label}
								to={link.to}
								icon={<Icon.Settings size={18} />}
								isActiveTab={isActiveTab}
								handleTabClick={this.handleTabClick}
							/>
						);
					})}
				</>
			);
		}

		return (
			<>
				<div className="side-navbar-container">
					<div className="side-navbar-items">
						<div className="navbar-logo">{logoSrc && <img className="logo" src={logoSrc} alt=""></img>}</div>

						<div className="navbar-item-container">
							<h2 className="navbar-item-container__title">Workflow</h2>
							<div className="navbar-item-container__item">
								<NavbarLink
									label={"Client Summary"}
									value={"Client Summary"}
									to="/"
									icon={<Icon.Home size={18} />}
									isActiveTab={isActiveTab}
									handleTabClick={this.handleTabClick}
								/>

								<NavbarLink
									label={"Engagements"}
									value={"Engagements"}
									to="/engagements"
									icon={<Icon.BookOpen size={18} />}
									isActiveTab={isActiveTab}
									handleTabClick={this.handleTabClick}
								/>

								<NavbarLink
									label={"Tasks"}
									value={"Tasks"}
									to="/tasks"
									icon={<Icon.List size={18} />}
									isActiveTab={isActiveTab}
									handleTabClick={this.handleTabClick}
								/>
							</div>
						</div>

						<div className="navbar-item-container">
							<h2 className="navbar-item-container__title">Clients</h2>
							<div className="navbar-item-container__item">
								<NavbarLink
									label={"Corporate"}
									value={"Corporate"}
									to="/clients/corporate"
									icon={<Icon.Briefcase size={18} />}
									isActiveTab={isActiveTab}
									handleTabClick={this.handleTabClick}
								/>

								<NavbarLink
									label={"Personal"}
									value={"Personal"}
									to="/clients/personal"
									icon={<Icon.User size={18} />}
									isActiveTab={isActiveTab}
									handleTabClick={this.handleTabClick}
								/>

								<NavbarLink
									label={"Trusts"}
									value={"Trusts"}
									to="/clients/trust"
									icon={<Icon.Shield size={18} />}
									isActiveTab={isActiveTab}
									handleTabClick={this.handleTabClick}
								/>

								<NavbarLink
									label={"Groups"}
									value={"Groups"}
									to="/clients/groups"
									icon={<Icon.Users size={18} />}
									isActiveTab={isActiveTab}
									handleTabClick={this.handleTabClick}
								/>
							</div>{" "}
						</div>

						<div className="navbar-item-container">
							<div className="navbar-item-container">
								<h2 className="navbar-item-container__title">Settings</h2>
								<div className="navbar-item-container__item">
									{protectedNavLinks}
									<NavbarLink
										label={"Edit Profile"}
										value={"Edit Profile"}
										to={`/settings/users/${userData.userId}`}
										icon={<Icon.User size={18} />}
										isActiveTab={isActiveTab}
										handleTabClick={this.handleTabClick}
									/>
									<NavbarLink
										label={"Submit a Ticket"}
										value={"Submit a Ticket"}
										icon={<Icon.Bell size={18} />}
										isActiveTab={isActiveTab}
										handleTabClick={this.handleTabClick}
										onClick={this.onSubmitATicket}
									/>
									<NavbarLink
										label={"Logout"}
										value={"Logout"}
										icon={<Icon.LogOut size={18} />}
										isActiveTab={isActiveTab}
										handleTabClick={this.handleTabClick}
										onClick={this.signOut}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<SupportTicketModal show={showTicketModal} onClose={this.onSupportTicketModalClose} />
			</>
		);
	}
}

export default Navbar;
