const currentEnvironment = "production";

const env = {
	development: {
		rest: "http://localhost:5001/api",
		plaid: "https://sandbox.plaid.com/",
		clientPortal: "http://localhost:3001/"
	},
	stage: {
		rest: "https://stage-api.getdocify.com/api",
		plaid: "https://sandbox.plaid.com/",
		clientPortal: "https://stage-app.srjca.com/"
	},
	production: {
		rest: "https://api.getdocify.com/api",
		plaid: "https://production.plaid.com/",
		clientPortal: "https://app.srjca.com/"
	}
};

const ConfigurationService = {
	/**
		* Returns the environment endpoints set above
	 
		* @return {String}
		*/
	resolveApi: () => {
		return env[currentEnvironment];
	}
};

export default ConfigurationService;
