import React from "react";
import { Redirect } from "react-router-dom";
import { toast as alert } from "react-toastify";
import UserService from "../../services/UserService";
import TextFieldInput from "../common/text_field_input/TextFieldInput";
import TextAreaField from "../common/text_area_field/TextAreaField";

import CompanyService from "../../services/CompanyService";
import WComponent from "../common/WComponent";
import PageHeading from "../common/page_heading/PageHeading";
import Button from "../common/button/Button";
import defaultImg from "../../default.png";
import "./settings.css";
import "./company-settings.css";

class CompanySettings extends WComponent {
	constructor() {
		super();

		this.state = {
			name: "",
			email: "",
			phone: "",
			sms: "",
			notes: "",
			file: null,
			logoSrc: "",
			loading: false,

			search: "",
			users: []
		};

		this.onChange = this.onChange.bind(this);
		this.fileInputRef = React.createRef();
		this.onFileChange = this.onFileChange.bind(this);
		this.updateCompany = this.updateCompany.bind(this);
	}

	componentDidMount() {
		this.resetComponent();
	}

	componentDidUpdate() {}

	async resetComponent() {
		await this.update({
			loading: true
		});

		await this.fetchCompany();
		await this.fetchLogo();

		await this.update({
			loading: false
		});
	}

	async fetchCompany() {
		try {
			let response = await CompanyService.get();

			let company = response.data;

			await this.update({
				name: company.name,
				email: company.email,
				phone: company.phone,
				sms: company.sms,
				notes: company.notes
			});
		} catch (error) {
			console.log(error);
		}
	}

	async fetchLogo() {
		this.update({
			loading: true
		});
		try {
			let response = await CompanyService.getLogo();
			const imageObjectUrl = URL.createObjectURL(response);
			this.update({ logoSrc: imageObjectUrl });
		} catch (error) {
			alert.error("Error while trying to retrieve the logo!");
			console.log(error);
		}
		this.update({
			loading: false
		});
	}

	async updateCompany() {
		let { loading, name, email, phone, sms, notes, file } = this.state;

		if (!this.isValid()) {
			alert.error("Missing fields!");
			return;
		}

		if (loading) {
			alert.info("Updating...");
			return;
		}

		this.update({
			loading: true
		});

		alert.info("Updating...");

		try {
			const formData = new FormData();
			formData.append("name", name);
			formData.append("email", email);
			formData.append("phone", phone);
			formData.append("sms", sms);
			formData.append("notes", notes);

			if (file) {
				formData.append("files", file, file.name);
			}

			await CompanyService.update(formData);

			alert.success("Updated Successfully!");
		} catch (error) {
			alert.error("Update Error!");
			console.log(error);
		}

		this.update({
			loading: false
		});
	}

	onChange(e) {
		this.update({ [e.target.name]: e.target.value });
	}

	onFileChange(e) {
		const file = e.target.files[0];
		if (file) {
			this.update({ file });
			this.createFilePreview(file);
		}
	}

	createFilePreview(file) {
		const reader = new FileReader();
		reader.onload = event => {
			this.update({ filePreview: event.target.result });
		};
		reader.readAsDataURL(file);
	}

	isDisabled() {
		let { loading } = this.state;

		return !this.isValid() || loading;
	}

	isValid() {
		let { name, email, phone, sms } = this.state;
		return name.length > 0 && email.length > 0 && phone.length > 0 && sms.length > 0;
	}

	render() {
		let { name, email, phone, sms, notes, logoSrc } = this.state;

		let userData = UserService.getUserData();

		if (userData.groupId === 2) {
			return <Redirect to={"/dashboard"} />;
		}

		return (
			<div className="company-settings">
				<PageHeading section="Settings" title={"Company"} />

				<div className="company-settings__form">
					<TextFieldInput
						containerClass="company-settings__form__input-c"
						title="Company Name"
						name="name"
						onChange={this.onChange}
						value={name}
						autoComplete="off"
					/>

					<TextFieldInput
						containerClass="company-settings__form__input-c"
						title="Email"
						name="email"
						onChange={this.onChange}
						value={email}
						autoComplete="off"
					/>

					<TextFieldInput
						containerClass="company-settings__form__input-c"
						title="Phone"
						name="phone"
						onChange={this.onChange}
						value={phone}
						autoComplete="off"
					/>

					<TextFieldInput
						containerClass="company-settings__form__input-c"
						title="SMS"
						name="sms"
						onChange={this.onChange}
						value={sms}
						autoComplete="off"
					/>

					<div className="company-settings__form__file-upload">
						<label className="company-settings__form__file-upload__title">Logo</label>

						<div className="company-settings__form__logo-preview" onClick={() => this.fileInputRef.current.click()}>
							{this.state.filePreview && (
								<img src={this.state.filePreview} alt="Company Logo" className="company-settings__form__file-upload__preview" />
							)}
							{!this.state.filePreview && (
								<img src={logoSrc || defaultImg} alt="Default Logo" className="company-settings__form__file-upload__preview" />
							)}
						</div>
						<input type="file" ref={this.fileInputRef} className="company-settings__form__file-upload-input" onChange={this.onFileChange} />
					</div>

					<TextAreaField
						containerClass="company-settings__form__input-c"
						title="Notes"
						name="notes"
						onChange={this.onChange}
						value={notes}
						autoComplete="off"
					/>

					<div className="company-settings__form__footer">
						<Button text="Update" onClick={this.updateCompany} disabled={this.isDisabled()} />
					</div>
				</div>
			</div>
		);
	}
}

export default CompanySettings;
