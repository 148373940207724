import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import WComponent from "../WComponent";
import "./text-area-field.css";

class TextAreaField extends WComponent {
	constructor(props) {
		super(props);
		this.state = {
			title: "",
			name: "",
			value: "",
			label: "",
			type: "",
			disabled: "",
			autoComplete: ""
		};
		this.onChange = this.onChange.bind(this);
	}

	onChange(e) {
		this.update({ [e.target.name]: e.target.value });
	}

	render() {
		let { type, containerClass, className, name, value, onChange, disabled, autoComplete, title, setRef } = this.props;

		return (
			<div className={clsx("text-area-field", containerClass)}>
				<span className="text-area-field__title">{title}</span>
				<textarea
					ref={setRef}
					type={type}
					className={clsx("text-area-field__input", className)}
					name={name}
					value={value}
					onChange={onChange}
					disabled={disabled}
					autoComplete={autoComplete}
				/>
			</div>
		);
	}
}

TextAreaField.propTypes = {
	title: PropTypes.string,
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	autoComplete: PropTypes.string
};

TextAreaField.defaultProps = {
	type: "textarea"
};

export default TextAreaField;
