import React from "react";
import "./user-bubbles.css";

const UserBubbles = ({ engagementUsers }) => {
	if (!engagementUsers || engagementUsers.length === 0) {
		return "No Assigned Users";
	}

	return (
		<div className="user-bubbles">
			{engagementUsers.map((eu, index) => {
				let engagementUser = eu.User;
				let userRole = eu.Role;

				if (!engagementUser || !userRole) {
					return null;
				}

				let initials = `${engagementUser.first_name[0]}${engagementUser.last_name[0]}`;
				let name = `${engagementUser.first_name} ${engagementUser.last_name}`;

				return (
					<div className="user-bubbles__item" key={`${index}-${eu.User}`}>
						{initials}
						<div className="user-bubbles__item__card">
							<div className="user-bubbles__item__card__initials">{initials}</div>
							<div className="user-bubbles__item__card__details">
								<div className="user-bubbles__item__card__details__name">{name}</div>
								<div className="user-bubbles__item__card__details__role">{userRole.name}</div>
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default UserBubbles;
