import WComponent from "../WComponent";
import clsx from "clsx";
import "./tool-card.css";

class ToolCard extends WComponent {
	render() {
		const { toolProps, disabled, onClick } = this.props;
		let { type, title, description, Icon } = toolProps;

		return (
			<div className={clsx("tool-card", { "tool-card--disabled": disabled })} onClick={() => !disabled && onClick(type)}>
				<div className="tool-card__icon">
					<Icon size={36} />
				</div>
				<div className="tool-card__text">
					<div className="tool-card__text__title">{title}</div>
					<div className="tool-card__text__blurb">{description}</div>
				</div>
			</div>
		);
	}
}

export default ToolCard;
