import UserService from "./UserService";
import Kichiri from "./KichiriService";
import moment from "moment";

const PlaidService = {
	/**
	 * Creates a plaid short link to share with a client
	 *
	 * @param {Number} clientId
	 * @returns {Object}
	 */
	async createPlaidShortLink({ clientId }) {
		let user = UserService.getUserData();

		try {
			let response = await Kichiri.tools.createPlaidShortLink(
				{},
				{
					clientId
				},
				user.authToken
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Fetches the Plaid status for a given client.
	 *
	 * @param {Object} params - The parameters for fetching the Plaid status.
	 * @param {string} params.clientId - The ID of the client.
	 * @returns {Promise<Object|null>} The Plaid status data if successful, otherwise null.
	 * @throws Will log an error to the console if the request fails.
	 */
	async getPlaidStatus({ clientId }) {
		let user = UserService.getUserData();

		try {
			let response = await Kichiri.tools.getPlaidStatus(
				{},
				{
					clientId
				},
				user.authToken
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Creates a plaid short link to share with a client
	 *
	 * @param {Number} clientId
	 * @returns {Object}
	 */
	async fetchAccounts({ clientId, searchTerm }) {
		let user = UserService.getUserData();

		try {
			let response = await Kichiri.tools.fetchPlaidAccounts(
				{},
				{
					clientId,
					searchTerm
				},
				user.authToken
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Creates a plaid short link to share with a client
	 *
	 * @param {Date} startDate
	 * @param {Date} endDate
	 * @param {String} plaidAccountId
	 * @param {Number} clientId
	 * @param {String} searchTerm
	 * @param {Boolean} isCSV
	 * @returns {Object}
	 */
	async fetchTransactions({ startDate, endDate, plaidAccountId, clientId, searchTerm, isCSV = true }) {
		let user = UserService.getUserData();

		try {
			let response = await Kichiri.tools.fetchPlaidTransactions(
				{},
				{
					clientId,
					startDate,
					endDate,
					plaidAccountId,
					searchTerm,
					isCSV
				},
				user.authToken
			);

			let { csvTransactions, clientName } = response.data;

			// Create a Blob from the CSV string
			let convertedStartDate = moment(startDate).format("YYYY-MM-DD");
			let convertedEndDate = moment(endDate).format("YYYY-MM-DD");
			let blob = new Blob([csvTransactions], { type: "text/csv" });
			let url = window.URL.createObjectURL(blob);
			let a = document.createElement("a");
			a.href = url;
			a.target = "_blank";
			a.download = `${clientName} - ${convertedStartDate} to ${convertedEndDate}.csv`;
			document.body.appendChild(a);
			a.click();
			a.remove();

			// Revoke the object URL to free up memory
			window.URL.revokeObjectURL(url);
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Fetches a list of plaid links for a given client id
	 *
	 * @param {Number} clientId
	 * @returns {Object}
	 */
	async fetchLinks({ clientId }) {
		let user = UserService.getUserData();

		try {
			let response = await Kichiri.tools.fetchPlaidLinks(
				{},
				{
					clientId
				},
				user.authToken
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Determines if the current client has any connected bank accounts
	 *
	 * @param {Number} clientId
	 * @returns
	 */
	async isPlaidAccountConnected({ clientId }) {
		let plaidLink = await this.fetchLinks({ clientId });

		if (!plaidLink) {
			return false;
		}

		if (plaidLink.status !== "completed") {
			return false;
		}

		return true;
	},

	/**
	 * Send a plaid connection request email to a specific contact
	 *
	 * @param {String} plaidLinkPublicId
	 * @param {Number} contactId
	 * @returns {Object}
	 */
	async sendConnectEmail({ plaidLinkPublicId, contactId }) {
		let user = UserService.getUserData();

		try {
			let response = await Kichiri.tools.sendConnectEmail({ plaidLinkPublicId, contactId }, {}, user.authToken);
			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	}
};

export default PlaidService;
