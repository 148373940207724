// External Libraries
import React from "react";
import * as Icon from "react-feather";
import clsx from "clsx";

// Services
import ClientService from "../../../services/ClientService";

// Components
import ClientGroupModal from "../ClientGroupsModal/ClientGroupModal";

// Common Components
import WComponent from "../../common/WComponent";
import Loader from "../../common/loader/Loader";
import SearchInput from "../../common/search_input/SearchInput";
import Button from "../../common/button/Button";

// Styling
import "./client-groups.css";

class ClientGroups extends WComponent {
	constructor(props) {
		super();
		this.state = {
			search: "",
			loading: false,

			clientGroups: [],
			desc: true,

			showModal: false,
			clientGroupId: "new"
		};
	}

	componentDidMount = () => {
		this.resetComponent();
	};

	componentDidUpdate = prevProps => {};

	resetComponent = async () => {
		let { clientType } = this.props.match.params;

		await this.update({
			type: clientType,

			desc: true,
			filters: {}
		});

		await this.fetchClientGroups();
	};

	fetchClientGroups = async () => {
		let { search } = this.state;

		await this.update({
			loading: true
		});

		try {
			let clientGroups = await ClientService.fetchClientGroups({ search });

			await this.update({
				clientGroups
			});
		} catch (error) {
			console.log(error);
		}

		await this.update({
			loading: false
		});
	};

	onClientGroupSelect = async ({ clientGroup }) => {
		await this.update({
			clientGroupId: clientGroup.id,
			showModal: true
		});
	};

	onNewClientGroup = async () => {
		await this.update({
			clientGroupId: "new",
			showModal: true
		});
	};

	onSearch = async event => {
		await this.update({
			search: event.target.value
		});

		await this.fetchClientGroups();
	};

	onModalClose = async () => {
		await this.update({
			showModal: false
		});

		await this.fetchClientGroups();
	};

	renderSortIcon = ({ field }) => {
		let { sortedField, desc } = this.state;
		return sortedField === field && (desc ? <Icon.ChevronUp size={14} /> : <Icon.ChevronDown size={14} />);
	};

	render() {
		let { loading, clientGroups, search, showModal, clientGroupId } = this.state;

		return (
			<div className="container">
				<div className="container-header">
					<div className="container-header-title">
						<h3 className="container-header-title__sub">Clients</h3>
						<h1 className="container-header-title__name">Groups</h1>
					</div>

					<div className="container-header-options">
						<Button view="secondary" icon={<Icon.Plus size={18} />} onClick={this.onNewClientGroup} />

						<SearchInput
							placeholder='Search Clients, Contacts, and Year Ends (eg. "Joeyco", "January") ....'
							onChange={this.onSearch}
							value={search}
						/>
					</div>
				</div>

				<div className="test-container">
					{loading && (
						<div className="container-loader">
							<Loader />
						</div>
					)}

					{clientGroups.length === 0 && !loading && (
						<div className="container-empty">
							<div>
								<Icon.Frown size={64} />
							</div>
							<div>... no client groups ...</div>
						</div>
					)}

					{clientGroups.length > 0 && !loading && (
						<div className={clsx("t", "t--compact")}>
							<div className="t__header">
								<div onClick={() => this.sortBy({ field: "name" }, {})} className="t__header__item">
									Client Group {this.renderSortIcon({ field: "name" })}
								</div>
							</div>

							{clientGroups.map((clientGroup, index) => {
								let odd = index % 2 === 0;

								return (
									<div className={clsx("t__row", { "t__row--stripe": odd })} key={index}>
										<div className="t__row__item" onClick={() => this.onClientGroupSelect({ clientGroup })}>
											{clientGroup.name}
										</div>
									</div>
								);
							})}
						</div>
					)}
				</div>

				<ClientGroupModal show={showModal} clientGroupId={clientGroupId} onClose={this.onModalClose} />
			</div>
		);
	}
}

export default ClientGroups;
