import React from "react";
import * as Icon from "react-feather";
import moment from "moment";
import WComponent from "../common/WComponent";

import Button from "../common/button/Button";
import { BudgetModal } from "./modals/BudgetModal";
import EngagementService from "../../services/EngagementService";

import UtilityService from "../../services/UtilityService";
import TextFieldInput from "../common/text_field_input/TextFieldInput";
import "./budgets.css";
import clsx from "clsx";
import { toast as alert } from "react-toastify";

class Budgets extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,

			showModal: false,
			modalView: "create",

			budgets: [],
			actualEditIndex: null,
			budgetEditIndex: null,

			newBudgetVal: "",
			newActualVal: ""
		};

		this.noteModal = null;
		this.budgetInputRef = React.createRef();
		this.inputRef = React.createRef();
	}

	componentDidMount = () => {
		this.resetComponent();
	};

	componentDidUpdate(prevProps, prevState) {
		// Check for engagementId change
		let newProps = this.props;
		let oldProps = prevProps;

		if (oldProps.engagementId !== newProps.engagementId) {
			this.resetComponent();
		}

		// Focus input if actualEditIndex changes
		if (this.state.actualEditIndex !== prevState.actualEditIndex && this.state.actualEditIndex !== null) {
			this.inputRef.current?.focus();
		}

		if (this.state.budgetEditIndex !== prevState.budgetEditIndex && this.state.budgetEditIndex !== null) {
			this.budgetInputRef.current?.focus();
		}
	}

	resetComponent = async () => {
		let { engagementId, clientId } = this.props;
		let { fetchBudgets, update } = this;

		await update({
			engagementId: engagementId || 0,
			clientId: clientId || 0,
			loading: true
		});

		await fetchBudgets();
		await update({ loading: false });
	};

	fetchBudgets = async () => {
		const { engagementId } = this.props;
		const { update } = this;

		let response = await EngagementService.listBudgets({ engagementId });

		if (response) {
			await update({
				budgets: response.data
			});
		}
	};

	onNewBudget = () => {
		let { update } = this;
		update({
			showModal: true,
			modalView: "create"
		});
	};

	isEmpty = ({ valueToUpdate }) => {
		return !(valueToUpdate.length > 0);
	};

	// New method to validate inputs
	validateInputs = ({ valueToUpdate }) => {
		const { isEmpty } = this;
		let errorMessages = []; // Array to collect error messages

		if (isEmpty({ valueToUpdate })) {
			errorMessages.push("Missing fields!");
		}

		// Validate budget input using isValidFormat
		if (!UtilityService.validateBudgetFormat(valueToUpdate)) {
			errorMessages.push("Please enter time in the format: 1h 30m or 1h or 30m");
		}

		return errorMessages; // Return collected error messages
	};

	onUpdateBudget = async ({ field, value, index }) => {
		const { validateInputs, update, fetchBudgets } = this;
		let { engagementId } = this.props;

		let created = false;

		await update({
			loading: true
		});

		let errorMessages = validateInputs({ valueToUpdate: value });

		if (errorMessages.length > 0) {
			alert.error(errorMessages.join(" ")); // Display all error messages
			return created;
		}

		try {
			await EngagementService.updateBudget({ engagementId, field, value, index });

			created = true;

			if (field == "budget") {
				await update({
					loading: false,
					budgetEditIndex: null,
					newBudgetVal: ""
				});
			} else {
				await update({
					loading: false,
					actualEditIndex: null,
					newActualVal: ""
				});
			}

			await fetchBudgets();
		} catch (error) {
			console.log(error);
			alert.error("Create error!");
			created = false;
		}

		await update({
			loading: false
		});
	};

	onRemoveBudget = async ({ index }) => {
		let { fetchBudgets, update } = this;
		let { engagementId } = this.props;

		await update({
			loading: true
		});

		try {
			await EngagementService.removeBudget({ engagementId, index });

			await fetchBudgets();
		} catch (error) {
			console.log(error);
			alert.error("Remove error!");
		}

		await update({
			loading: false
		});
	};

	onCloseModal = async () => {
		let { update, fetchBudgets } = this;
		await update({
			showModal: false
		});
		await fetchBudgets();
	};

	parseToMinutes = ({ duration }) => {
		let totalMinutes = 0;

		// Split the duration string by spaces
		const parts = duration.split(" ");

		parts.forEach(part => {
			if (part.endsWith("h")) {
				totalMinutes += parseFloat(part) * 60; // Convert hours to minutes, allowing for decimal values
			} else if (part.endsWith("m")) {
				totalMinutes += parseFloat(part); // Minutes remain as is, allowing for decimal values
			}
		});

		return totalMinutes;
	};

	// New method to compare actual and budget values
	isActualGreaterThanBudget = ({ actual, budget }) => {
		let { parseToMinutes } = this;

		// Check if actual is not null or empty
		if (!actual) {
			return false;
		}

		const actualMinutes = parseToMinutes({ duration: actual });
		const budgetMinutes = parseToMinutes({ duration: budget });

		return actualMinutes > budgetMinutes;
	};

	render() {
		let { loading, budgets, showModal, modalView, actualEditIndex, budgetEditIndex, newBudgetVal, newActualVal } = this.state;
		const {
			onNewBudget,
			onCloseModal,
			budgetInputRef,
			update,
			onUpdateBudget,
			inputRef,

			isActualGreaterThanBudget,
			onRemoveBudget,
			parseToMinutes
		} = this;
		const { engagementId } = this.props;

		return (
			<div className="container">
				<BudgetModal engagementId={engagementId} view={modalView} show={showModal} onClose={onCloseModal} />

				<div className="budgets__body">
					<div className="budgets__body__hd">
						<div className="budgets__body__hd__title">Budgets</div>
						<Button view="secondary" icon={<Icon.Plus size={18} />} onClick={onNewBudget} />
					</div>

					{!(budgets.length > 0) && !loading && (
						<div className="budgets__body__empty">
							<div>
								<Icon.Frown size={64} />
							</div>
							<div>... no budgets ...</div>
						</div>
					)}

					{budgets &&
						budgets.map((data, index) => {
							const isEditing = actualEditIndex === index;
							const isBudgetEditing = budgetEditIndex === index;

							const isActualGreater = isActualGreaterThanBudget({ actual: data.actual, budget: data.budget });

							return (
								<div className="budgets__body__item" key={index}>
									<div className="budgets__body__item__task">{data.resource}</div>

									<div className="budgets__body__item__content">
										Budget
										<div
											className={clsx(
												"budgets__body__item__content__val",
												isBudgetEditing && "budgets__body__item__content__val--editing"
											)}
										>
											<Icon.Clock size={14} color="#000" />

											{isBudgetEditing && (
												<TextFieldInput
													ref={budgetInputRef}
													className="budgets__body__item__content__val__input"
													title=""
													name="actualValue"
													onChange={e => update({ newBudgetVal: e.target.value })}
													value={newBudgetVal || ""}
													autoComplete="off"
												/>
											)}

											{!isBudgetEditing && data.budget && <div className="budgets__body__item__content__val__text">{data.budget}</div>}

											{!isBudgetEditing && (
												<Icon.Edit
													className="budgets__body__item__content__val__edit"
													size={14}
													color="var(--company-color)"
													onClick={() => {
														update({ budgetEditIndex: index });
													}}
												/>
											)}

											{isBudgetEditing && (
												<>
													<Icon.X
														className="budgets__body__item__content__val__delete"
														size={14}
														color="#FF6B6B"
														strokeWidth={3}
														onClick={() => {
															update({ budgetEditIndex: null, newBudgetVal: 0 });
														}}
													/>
													<Icon.Check
														className="budgets__body__item__content__val__check"
														size={14}
														strokeWidth={3}
														color="var(--company-color)"
														onClick={() => {
															onUpdateBudget({
																field: "budget",
																value: newBudgetVal,
																index: budgetEditIndex
															});
														}}
													/>
												</>
											)}
										</div>
									</div>

									<div className="budgets__body__item__content">
										<div>Actual</div>

										<div className={clsx("budgets__body__item__content__val", isEditing && "budgets__body__item__content__val--editing")}>
											<Icon.Clock size={14} color="#000" />

											{isEditing && (
												<TextFieldInput
													ref={inputRef}
													className="budgets__body__item__content__val__input"
													title=""
													name="actualValue"
													onChange={e => update({ newActualVal: e.target.value })}
													value={newActualVal || ""}
													autoComplete="off"
												/>
											)}

											{!isEditing && data.actual && <div className="budgets__body__item__content__val__text">{data.actual}</div>}

											{!isEditing && !data.actual && <div>No entry</div>}

											{!isEditing && (
												<Icon.Edit
													className="budgets__body__item__content__val__edit"
													size={14}
													color="var(--company-color)"
													onClick={() => {
														update({ actualEditIndex: index, newActualVal: data.actual });
													}}
												/>
											)}

											{isEditing && (
												<>
													<Icon.X
														className="budgets__body__item__content__val__delete"
														size={14}
														color="#FF6B6B"
														strokeWidth={3}
														onClick={() => {
															update({ actualEditIndex: null, newActualVal: 0 });
														}}
													/>
													<Icon.Check
														className="budgets__body__item__content__val__check"
														size={14}
														strokeWidth={3}
														color="var(--company-color)"
														onClick={() => {
															onUpdateBudget({
																field: "actual",
																value: newActualVal,
																index: actualEditIndex
															});
														}}
													/>
												</>
											)}
										</div>
									</div>

									<div className="budgets__body__item__content">
										<div className="budgets__body__item__label">Over/Under</div>
										{data.difference && (
											<>
												{parseToMinutes({ duration: data.difference }) == 0 && (
													<div className="budgets__body__item__content__val">{data.difference}</div>
												)}
												{parseToMinutes({ duration: data.difference }) !== 0 && (
													<div
														className={clsx("budgets__body__item__content__val", {
															"budgets__body__item__content__val--over": isActualGreater,
															"budgets__body__item__content__val--under": !isActualGreater
														})}
													>
														{isActualGreater ? `+${data.difference}` : `-${data.difference}`}
													</div>
												)}
											</>
										)}
										{!data.difference && <div className="budgets__body__item__content__val">N/A</div>}
									</div>

									<Icon.XCircle
										className="budgets__body__item__delete"
										size={28}
										color="#FF6B6B"
										onClick={() => {
											onRemoveBudget({ index });
										}}
									/>
								</div>
							);
						})}
				</div>
			</div>
		);
	}
}

export default Budgets;
