import React from "react";
import * as Icon from "react-feather";
import moment from "moment";
import clsx from "clsx";

import { ORDER_QUERIES } from "../../../constants/EngagementTemplates";

import EngagementService from "../../../services/EngagementService";

import SearchInput from "../../common/search_input/SearchInput";
import WComponent from "../../common/WComponent";
import EngagementTemplateModal from "./eng_template__modal/EngagementTemplateModal";

import Button from "../../common/button/Button";
import Tabs from "../../common/tabs/Tabs";
import Tab from "../../common/tabs/tab/Tab";
import PageHeading from "../../common/page_heading/PageHeading";
import Loader from "../../common/loader/Loader";

import "./engagement-templates.css";

class EngagementTemplates extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			// View Statue
			loading: true,

			// Data State
			engagementTemplates: [],
			engagementTemplateId: "new",
			search: "",

			filter: "active",

			sortField: ORDER_QUERIES.createdAt,
			sortOrder: "asc",

			showEngagementTemplateModal: false
		};
	}

	componentDidMount() {
		this.resetComponent();
	}

	componentDidUpdate(prevProps) {
		// let { raw } = this.props;
		// let newProps = raw ? this.props : this.props.match.params;
		// let oldProps = raw ? prevProps : prevProps.match.params;
		// if (oldProps.engagementId !== newProps.engagementId || oldProps.assignedUserId !== newProps.assignedUserId || oldProps.title !== newProps.title) {
		// 	this.resetComponent();
		// }
	}

	resetComponent = async () => {
		await this.update({ loading: true });
		await this.fetchEngagementTemplates();
		await this.update({ loading: false });
	};

	sortBy = async field => {
		let { sortOrder } = this.state;

		await this.update({
			sortField: field,
			sortOrder: sortOrder === "desc" ? "asc" : "desc"
		});

		await this.fetchEngagementTemplates();
	};

	fetchEngagementTemplates = async () => {
		let { sortField, sortOrder, search } = this.state;

		try {
			let engagementTemplates = await EngagementService.fetchEngagementTemplates({ sortField, sortOrder, searchTerm: search });

			await this.update({ engagementTemplates });
		} catch (error) {
			console.log(error);
		}
	};

	onSearch = async event => {
		await this.update({
			search: event.target.value
		});

		await this.fetchEngagementTemplates();
	};

	onNewTemplate = async () => {
		await this.update({
			engagementTemplateId: "new",
			showEngagementTemplateModal: true
		});
	};

	onUpdateTemplate = async engagementTemplateId => {
		await this.update({
			engagementTemplateId: engagementTemplateId,
			showEngagementTemplateModal: true
		});
	};

	onEngagementTemplateModalClose = async () => {
		await this.update({
			loading: true,
			showEngagementTemplateModal: false
		});

		await this.fetchEngagementTemplates();

		await this.update({
			loading: false
		});
	};

	onFilterSelect = async filter => {
		await this.update({ filter: filter.id });

		await this.fetchEngagementTemplates();
	};

	renderSortIcon = field => {
		let { sortField, sortOrder } = this.state;

		return sortField === field && (sortOrder === "desc" ? <Icon.ChevronUp size={14} /> : <Icon.ChevronDown size={14} />);
	};

	render() {
		let { filter, loading, search, engagementTemplateId, engagementTemplates, showEngagementTemplateModal } = this.state;
		let { onFilterSelect } = this;

		return (
			<div className="container">
				<PageHeading section="Settings" title={"Engagement Templates"} />

				<div className="eng-templates__header">
					<Tabs onSelect={onFilterSelect} selected={filter}>
						<Tab id={"all"} text="All" />
						<Tab id={"active"} text="Active" />
					</Tabs>

					<div className="eng-templates__header__actions">
						<Button view="secondary" onClick={this.onNewTemplate} icon={<Icon.Plus size={18} />} />

						<SearchInput placeholder="Search..." value={search} onChange={this.onSearch} />
					</div>
				</div>

				{loading && (
					<div className="container-loader">
						<Loader />
					</div>
				)}

				{engagementTemplates.length === 0 && !loading && (
					<div className="container-empty">
						<div>
							<Icon.Frown size={64} />
						</div>
						<div>... no engagement templates ...</div>
					</div>
				)}

				{engagementTemplates.length > 0 && !loading && (
					<div className={clsx("t", "t--compact")}>
						<div className="t__header">
							<div onClick={() => this.sortBy(ORDER_QUERIES.name)} className="t__header__item">
								Name {this.renderSortIcon(ORDER_QUERIES.name)}
							</div>
							<div onClick={() => this.sortBy(ORDER_QUERIES.createdAt)} className="t__header__item">
								Created At {this.renderSortIcon(ORDER_QUERIES.createdAt)}
							</div>
						</div>

						{engagementTemplates.map((engagementTemplate, index) => {
							let odd = index % 2 === 0;
							return (
								<div
									className={clsx("t__row", { "t__row--stripe": odd })}
									key={index}
									onClick={() => this.onUpdateTemplate(engagementTemplate.id)}
								>
									<div className="t__row__item">{engagementTemplate.name}</div>
									<div className="t__row__item">{moment(engagementTemplate.created_at).format("MMMM Do YYYY @ h:mma")}</div>
								</div>
							);
						})}
					</div>
				)}

				<EngagementTemplateModal
					show={showEngagementTemplateModal}
					engagementTemplateId={engagementTemplateId}
					onClose={this.onEngagementTemplateModalClose}
					onCreate={() => {}}
					{...this.props}
				/>
			</div>
		);
	}
}

export default EngagementTemplates;
