import React from "react";
import Modal from "react-responsive-modal";
import { toast as alert } from "react-toastify";
import SearchableDropdown from "../../../common/searchable_dropdown/SearchableDropdown";
import TextFieldInput from "../../../common/text_field_input/TextFieldInput";
import TextAreaField from "../../../common/text_area_field/TextAreaField";
import RelativeDate from "../../../common/RelativeDate/RelativeDate";
import { DEFAULT_RELATIVE_DUE_DATE } from "../../../../constants/EngagementTemplates";
import WComponent from "../../../common/WComponent";
import Button from "../../../common/button/Button";
import Loader from "../../../common/loader/Loader";

import "./engagement-task-modal.css";

class EngagementTaskModal extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			name: "",
			assignedUser: null,
			assignedUserName: "",
			deadline: DEFAULT_RELATIVE_DUE_DATE,
			notes: ""
		};
	}

	componentDidMount() {
		this.resetComponent();
	}

	componentDidUpdate(prevProps) {
		let { action, taskToUpdateIndex, engTasks } = this.props;

		if (action === "update" && taskToUpdateIndex !== prevProps.taskToUpdateIndex) {
			const taskToUpdate = engTasks[taskToUpdateIndex];

			this.setState({
				name: taskToUpdate.name,
				assignedUser: taskToUpdate.assignedUser,
				assignedUserName: taskToUpdate.assignedUser.name,
				deadline: taskToUpdate.dueDate,
				notes: taskToUpdate.notes
			});
		}
	}

	resetComponent = () => {
		this.clearModal();
	};

	isValid = () => {
		let { name, assignedUser } = this.state;
		return name.length > 0 && assignedUser !== null;
	};

	onSubmit = async () => {
		let { action, onClose } = this.props;
		let { name, assignedUser, deadline, notes } = this.state;

		if (!this.isValid()) {
			alert.error("Missing fields!");
			return;
		}

		await this.update({
			loading: true
		});

		let data = {
			name,
			assignedUser,
			dueDate: deadline,
			notes
		};

		if (action === "update") {
			this.onUpdateTask(data);
		} else {
			this.onAddTask(data);
		}

		this.clearModal();

		onClose();

		this.update({
			loading: false
		});
	};

	onAddTask = data => {
		let { engTasks, updateEngagementTasks } = this.props;
		const newIndex = engTasks.length;
		const newTask = { ...data, index: newIndex };
		engTasks.push(newTask);
		if (updateEngagementTasks) {
			updateEngagementTasks(engTasks);
		}
	};

	onUpdateTask = data => {
		let { engTasks, taskToUpdateIndex, updateEngagementTasks } = this.props;
		engTasks[taskToUpdateIndex] = data;
		if (updateEngagementTasks) {
			updateEngagementTasks(engTasks);
		}
	};

	clearModal = () => {
		this.setState({
			name: "",
			assignedUser: null,
			assignedUserName: "",
			deadline: DEFAULT_RELATIVE_DUE_DATE,
			notes: ""
		});
	};

	onRelativeDueDateChange = relativeDueDate => {
		this.setState({ deadline: relativeDueDate });
	};

	render() {
		const { show, onClose, action } = this.props;
		const { name, assignedUserName, deadline, notes, loading } = this.state;
		const { onSubmit } = this;

		return (
			<Modal open={show} onClose={onClose} center>
				<div>
					<div className="modal-title">{"New Task Template"}</div>

					{loading && (
						<div className="eng-task-m__loader">
							<Loader />
						</div>
					)}

					{!loading && (
						<form className="task-modal-form">
							<TextFieldInput
								containerClass="eng-task-m__form__input-c"
								title="Task Name"
								name="name"
								onChange={event => {
									this.setState({ name: event.target.value });
								}}
								value={name}
								autoComplete="off"
							/>

							<RelativeDate containerClass="eng-task-m__form__input-c" onChange={this.onRelativeDueDateChange} data={deadline} />

							<SearchableDropdown
								containerClass="eng-task-m__form__input-c"
								title="Assigned Role"
								onSelect={user => {
									this.setState({ assignedUser: user, assignedUserName: user.name });
								}}
								selectedName={assignedUserName}
								type={"roles"}
								showAll
							/>

							<TextAreaField
								title="Notes"
								type="text"
								name="notes"
								value={notes}
								onChange={event => {
									this.setState({ notes: event.target.value });
								}}
							/>
						</form>
					)}
				</div>

				<div className="modal-footer">
					<Button view="secondary" onClick={onClose} text={"Cancel"} />
					<Button onClick={onSubmit} text={action === "new" ? "Create Task" : "Update Task"} />
				</div>
			</Modal>
		);
	}
}

export default EngagementTaskModal;
