import React from "react";
import Modal from "react-responsive-modal";
import moment from "moment";
import { toast as alert } from "react-toastify";
import ClientService from "../../../services/ClientService";
import EngagementService from "../../../services/EngagementService";
import UtilityService from "../../../services/UtilityService";

import "./auto-generate-modal.css";
import WComponent from "../../common/WComponent";
import Button from "../../common/button/Button";

class AutoGenerateModal extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			show: false,

			engagement: null,
			client: null,

			flows: [],
			selectedFlows: {}
		};
	}

	componentDidMount = () => {
		this.resetComponent();
	};

	componentDidUpdate = prevProps => {
		let { engagementId, show } = this.props;

		if (prevProps.engagementId !== engagementId || prevProps.show !== show) {
			this.resetComponent();
		}
	};

	resetComponent = async () => {
		let { engagementId, show } = this.props;

		if (!show) {
			await this.update({ show });
			return;
		}

		await this.update({ loading: true });

		let engagement = await EngagementService.fetchEngagement(engagementId);
		let flows = await EngagementService.fetchFlows({});
		let { client } = await ClientService.fetchClient({ clientId: engagement.client_id });

		await this.update({
			engagement,
			flows,
			client,
			loading: false,
			show,
			selectedFlows: []
		});
	};

	onClose = async () => {
		await this.update({
			show: false
		});
	};

	onCreate = async () => {
		let { selectedFlows, engagement, client } = this.state;

		let success = true;

		let assignedUsers = engagement.engagement_users.map(eu => {
			return {
				user_id: eu.user_id,
				role_id: eu.role_id
			};
		});

		for (let flow in selectedFlows) {
			let flowId = flow;
			let { flowStateId, name, code: flowCode } = selectedFlows[flowId];

			try {
				let newName = `${name} - ${client.name} - ${moment(engagement.fiscal_year_end).format("YYYY-MM-DD")}`;

				let tasksFormatted = engagement.tasks.map(entry => {
					return {
						name: entry.name,
						engagementId: entry.id,
						notes: entry.notes,
						dueDate: entry.deadline,
						assigned_role_id: entry.assigned_user_id
					};
				});

				let fiscalYearEnd = moment(engagement.fiscal_year_end).format("YYYY-MM-DD");
				let recurringEngagement = engagement.RecurringEngagement;
				let rrule = UtilityService.isNotNullish(recurringEngagement) ? recurringEngagement.rrule : undefined;

				await EngagementService.create({
					name: newName,
					teamId: engagement.team_id,
					clientId: engagement.client_id,
					flowId: flowId,
					flowStateId: flowStateId,
					dueDate: EngagementService.deriveDueDateForEngagement({
						flowCode: flowCode,
						fiscalYearEnd,
						frequency: undefined
					}),
					dateIn: moment(engagement.date_in).format("YYYY-MM-DD"),
					fiscalYearEnd: fiscalYearEnd,
					assignedUsers,
					tasks: tasksFormatted,
					rrule: rrule,
					relativeDueDate: engagement.relativeDueDate
				});
			} catch (error) {
				console.log(error);

				success = false;
			}
		}

		if (success) {
			alert.success("Additional Engagements Created Successfully!");
		} else {
			alert.error("Some errors occurred trying to create additional engagements.");
		}

		await this.update({
			show: false
		});
	};

	onFlowClicked = async ({ flow }) => {
		let { selectedFlows } = this.state;

		if (typeof selectedFlows[flow.id] !== "undefined") {
			delete selectedFlows[flow.id];
		} else {
			selectedFlows[flow.id] = {
				flowStateId: flow.flow_states[0].id,
				name: flow.name,
				code: flow.code
			};
		}

		await this.update({
			selectedFlows
		});
	};

	renderFlows = () => {
		let { engagement, flows, selectedFlows } = this.state;

		return (
			<div className="pill-container">
				{flows.map(flow => {
					if (engagement.flow_id === flow.id) {
						return null;
					}

					return (
						<div className={`pill ${selectedFlows[flow.id] ? "pill--active" : ""} `} onClick={() => this.onFlowClicked({ flow })}>
							{flow.name}
						</div>
					);
				})}
			</div>
		);
	};

	render() {
		let { show, client, engagement } = this.state;

		if (!client) {
			return null;
		}

		return (
			<Modal open={show} onClose={this.onClose} center>
				<div className="agm">
					<div className="modal-title">Add more engagements ...</div>

					<div className="agm__blurb">
						Add more engagements for {client.name} . Settings from "{engagement.name}" will be copied over.
					</div>
					<div className="agm__flows">{this.renderFlows()}</div>
				</div>
				<div className="modal-footer">
					<Button view="seconary" onClick={this.onClose} text="Cancel" />
					<Button onClick={this.onCreate} text="Create" />
				</div>
			</Modal>
		);
	}
}

export default AutoGenerateModal;
