import React, { Component } from "react";
import * as Icon from "react-feather";
import Board from "react-trello";
import EngagementTaskModal from "../../workflow/EngagementTemplates/eng_task_modal/EngagementTaskModal";
import EngagementTaskCard from "../eng_task_card/EngagementTaskCard";
import "./engagement-tasks.css";
import AddItemButton from "../add_item_button/AddItemButton";

class TaskList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			engTasks: [],
			engagementTaskAction: "new",
			engTaskUpdateIndex: null,
			showEngagementTaskModal: false
		};
	}

	assignIndexToTasks = tasks => {
		return tasks.map((task, idx) => {
			if (typeof task.index === "undefined" || task.index === null) {
				return { ...task, index: idx };
			}
			return task;
		});
	};

	getSortedTasks = engTasks => {
		if (!engTasks) {
			return [];
		}
		let tasks = engTasks;
		const tasksWithIndex = this.assignIndexToTasks(tasks);
		const sortedTasks = tasksWithIndex.sort((a, b) => a.index - b.index);
		return sortedTasks;
	};

	componentDidMount() {
		const { tasks } = this.props;
		if (tasks) {
			this.setState({ engTasks: this.getSortedTasks(tasks) });
		}
	}

	handleCardMove = (fromLaneId, toLaneId, cardId, newIndex) => {
		const tasks = [...this.state.engTasks];
		const oldIndex = parseInt(cardId, 10);
		const [movedTask] = tasks.splice(oldIndex, 1);

		tasks.splice(newIndex, 0, movedTask);
		const updatedTasks = tasks.map((task, index) => ({ ...task, index }));
		this.setState({ engTasks: updatedTasks });
		this.props.updateEngagementSnapshot(updatedTasks);
	};

	onRemoveTask = index => {
		let prevTasks = [...this.state.engTasks];
		prevTasks.splice(index, 1);
		this.setState({ engTasks: prevTasks });
		this.props.updateEngagementSnapshot(prevTasks);
	};

	onUpdateEngTask = index => {
		this.setState({
			engagementTaskAction: "update",
			engTaskUpdateIndex: index,
			showEngagementTaskModal: true
		});
	};

	onNewEngTask = () => {
		this.setState({
			engagementTaskAction: "new",
			engTaskUpdateIndex: null,
			showEngagementTaskModal: true
		});
	};

	render() {
		const { showEngagementTaskModal, engagementTaskAction, engTasks, engTaskUpdateIndex } = this.state;

		const lane = {
			id: "taskLane",
			cards: engTasks.map((task, index) => ({
				id: index.toString(),
				task,
				index,
				onRemoveTask: this.onRemoveTask,
				onUpdateEngTask: this.onUpdateEngTask
			}))
		};

		return (
			<>
				<EngagementTaskModal
					show={showEngagementTaskModal}
					action={engagementTaskAction}
					onClose={() => {
						this.setState({
							engagementTaskAction: "new",
							engTaskUpdateIndex: null,
							showEngagementTaskModal: false
						});
					}}
					engTasks={engTasks}
					taskToUpdateIndex={engTaskUpdateIndex}
					updateEngagementTasks={this.props.updateEngagementSnapshot}
				/>

				<Board
					data={{ lanes: [lane] }}
					components={{ Card: EngagementTaskCard }}
					laneStyle={{
						backgroundColor: "transparent",
						width: "100%"
					}}
					draggable={true}
					onCardMoveWithinLane={this.handleCardMove}
					onCardMoveAcrossLanes={this.handleCardMove}
				/>

				<AddItemButton itemName={"Task"} onClick={this.onNewEngTask} />
			</>
		);
	}
}

export default TaskList;
