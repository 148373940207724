import React, { Component } from "react";
import * as Icon from "react-feather";

import "./checkbox.css";
import clsx from "clsx";

class Checkbox extends Component {
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
	}

	render() {
		let { containerClass, className, title, name, checked, onChange, isSmall, style } = this.props;

		return (
			<div
				className={clsx("checkbox", containerClass, {
					"checkbox--small": isSmall
				})}
				style={style}
				onClick={event => {
					onChange({ event, target: { checked: !checked, type: "checkbox", name } });
				}}
			>
				<div
					className={clsx("checkbox__input", className, {
						"checkbox__input--selected": checked,
						"checkbox__input--small": isSmall
					})}
					type="checkbox"
					title={title}
					name={name}
					checked={checked}
					autoComplete="off"
				>
					{checked && <Icon.Check size={14} color="white" />}
				</div>

				{title && <div className="checkbox__title">{title}</div>}
			</div>
		);
	}
}

export default Checkbox;
