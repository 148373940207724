import _ from "lodash";
import moment from "moment";
import Kichiri from "./KichiriService";
import UserService from "./UserService";

const UtilityService = {
	/**
	 * Simple Timeout promise
	 *
	 * @param {Number} ms
	 * @return {Promise}
	 */
	timeout: function(ms) {
		return new Promise(function(resolve, reject) {
			setTimeout(resolve, ms);
		});
	},

	async submitTicket({ name, description }) {
		let { authToken } = UserService.getUserData();

		try {
			let response = await Kichiri.support.create({ name, description }, {}, authToken);

			console.log(response);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	getOrdinal(n) {
		var s = ["th", "st", "nd", "rd"];
		var v = n % 100;
		return n + (s[(v - 20) % 10] || s[v] || s[0]);
	},

	/**
	 *
	 * @param {unknown} value
	 * @returns {boolean}
	 */
	isNullish: value => _.isNil(value),

	/**
	 *
	 * @param {unknown} value
	 * @returns {boolean}
	 */
	isNotNullish: value => !_.isNil(value),

	/**
	 * Get the relative dates for message dividers
	 *
	 * @param {Date} date
	 */
	getRelativeDate(date) {
		let momentDate = moment(date);

		if (momentDate.year() !== moment().year()) {
			return momentDate.format("ddd, MMM Do, YYYY");
		}

		return momentDate.calendar(null, {
			sameDay: "[Today]",
			nextDay: "[Tomorrow]",
			nextWeek: "dddd",
			lastDay: "[Yesterday]",
			lastWeek: "ddd, MMM Do",
			sameElse: "ddd, MMM Do"
		});
	},

	/*
	 * Returns a boolean whether or not container is empty
	 * @param {unknown} value
	 * @returns {boolean}
	 */
	isEmpty: value => _.isEmpty(value),

	/**
	 *
	 * @param {object} object
	 * @param {string} path
	 * @returns
	 */
	isPathFoundInObject: (object, path) => _.hasIn(object, path),

	/**
	 *
	 * @param {any} value
	 * @param {any} defaultValue
	 * @returns {any}
	 */
	defaultTo: ({ value, defaultValue }) => _.defaultTo(value, defaultValue),

	/**
	 * @description Safetly derives the property from object otherwise defaults to undefined, similar to ?. chaning operator in newer versions of JS
	 * @param {object} object
	 * @param {string} path
	 * @returns {any}
	 */
	getPropertyFromObject: ({ object, path }) => _.get(object, path),

	/**
	 * @description Create a deep copy of an object
	 * @param {Object} object
	 * @returns {Object}
	 */
	deepCopy: object => _.cloneDeep(object),

	/**
	 * @description Returns a boolean whether or not the text is in email format
	 * @param {object} param
	 * @param {string} param.text
	 * @returns {boolean} true if text is in email format
	 */
	isTextInEmailFormat: ({ text }) => {
		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailPattern.test(text);
	},

	/**
	 * Validates the budget format to ensure it follows the "1h 30m" or "1h" or "30m" pattern.
	 *
	 * @param {string} budget - The budget string to validate.
	 * @returns {boolean} - Returns true if the format is valid, otherwise false.
	 */
	validateBudgetFormat: budget => {
		return budget.split(" ").every(part => {
			const num = parseInt(part);
			return (part.endsWith("h") && !isNaN(num)) || (part.endsWith("m") && !isNaN(num));
		});
	},

	/**
	 * @description Replacer function for notes/references
	 * @param {boolean} capitalizeFirstLetter - Flag to capitalize the first letter of the note type.
	 * @param {string} type - The type of note, either "reference" or "note".
	 * @returns {string} - The formatted note type, potentially capitalized.
	 */
	getNoteType: ({ capitalizeFirstLetter, type }) => {
		let typeToReturn = type === "reference" ? "reference" : "note";
		// Capitalize if 'capitalizeFirstLetter' flag is set to true
		return capitalizeFirstLetter ? typeToReturn.charAt(0).toUpperCase() + typeToReturn.slice(1) : typeToReturn;
	}
};

export default UtilityService;
