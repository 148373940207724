import UserService from "./UserService";
import Kichiri from "./KichiriService";

const CustomFieldService = {
	/**
	 * Fetches list of custom fields
	 * @returns {Object}
	 */
	async fetchFields() {
		let user = UserService.getUserData();

		try {
			let response = await Kichiri.field.list({}, {}, user.authToken);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return [];
	},

	/**
	 * Fetches a custom field
	 * @param {Number} fieldId
	 * @returns {Object}
	 */
	async fetchField({ fieldId }) {
		let user = UserService.getUserData();

		try {
			let response = await Kichiri.field.get({ fieldId }, {}, user.authToken);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Updates a custom field
	 * @param {Number} fieldId
	 * @param {String} name
	 * @param {String} field
	 * @param {String} description
	 * @param {String} type
	 * @returns {Object}
	 */
	async updateField({ fieldId, name, field, description, type }) {
		let user = UserService.getUserData();

		try {
			let response = await Kichiri.field.update({ fieldId, name, field, description, type }, {}, user.authToken);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Creates a custom field
	 * @param {String} name
	 * @param {String} field
	 * @param {String} description
	 * @param {String} type
	 * @returns {Object}
	 */
	async createField({ name, field, description, type }) {
		let user = UserService.getUserData();

		try {
			let response = await Kichiri.field.create({ name, field, description, type }, {}, user.authToken);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	}
};

export default CustomFieldService;
