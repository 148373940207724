import React from "react";
import Modal from "react-responsive-modal";

import WComponent from "../../../../common/WComponent";
import "./email-sent-modal.css";
import Button from "../../../../common/button/Button";

class EmailSentModal extends WComponent {
	render() {
		const { show, onClose } = this.props;

		return (
			<Modal open={show} onClose={onClose} center showCloseIcon={false}>
				<div className="email-sent">
					<img className="email-sent__check-icon" alt="icon check blue" src="/assets/icon-check-blue.gif" />
					<span className="email-sent__title">Email sent!</span>
					<span className="email-sent__subtitle">Your contact will get an email shortly, regarding next steps.</span>

					<Button className="email-sent__ok-btn" text="Ok" onClick={onClose} />
				</div>
			</Modal>
		);
	}
}

export default EmailSentModal;
