// External Libraries
import React from "react";

// Common Components
import TextFieldInput from "../../../common/text_field_input/TextFieldInput";
import WComponent from "../../../common/WComponent";

// Services
import ReconciliationService from "../../../../services/ReconciliationService";

// Styling
import "./reconciliation-tool.css";

class ReconSummary extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			openingBal: 0,
			craBal: 0,
			cyIncomeTaxExpense: 0
		};
	}

	onNumericChange = event => {
		event.preventDefault();

		let re = /^-?[0-9]*(?:\.[0-9]*)?$/;
		let value = event.target.value;
		let stateKey = event.target.name;

		if (value === "") {
			this.update({
				[stateKey]: 0
			});

			return;
		}

		if (re.test(value)) {
			this.update({
				[stateKey]: value
			});
			return;
		}
	};

	formatNumber(num) {
		return Number(num).toLocaleString("en-US", { minimumFractionDigits: 2 });
	}

	downloadSummary = async () => {
		let { clientId, filteredReconciliationData } = this.props;
		let { openingBal, craBal, cyIncomeTaxExpense } = this.state;

		await this.update({ loading: true });

		await ReconciliationService.downloadSummary({
			filteredReconciliationData,
			inputParams: {
				openingBal,
				craBal,
				cyIncomeTaxExpense
			},
			clientId
		});
	};

	render() {
		let { filteredReconciliationData } = this.props;
		let { currentYear, priorYear, pyInstalment, cyInstalment, cyInterestIncome, cyInterestPaid, month, day } = filteredReconciliationData;
		let { openingBal, craBal, cyIncomeTaxExpense } = this.state;

		cyInstalment = -cyInstalment;
		pyInstalment = pyInstalment;

		let closingInstalmentBalance = pyInstalment + cyInstalment;
		let incomeTaxExpenseBalance = parseFloat(cyIncomeTaxExpense);
		let intAndPenPayRec = parseFloat(openingBal).toFixed(2) - cyInterestIncome - cyInterestPaid;
		let instalmentRecPriorPay = intAndPenPayRec - pyInstalment;
		let instalmentCurrentPayRec = instalmentRecPriorPay - cyInstalment;
		let currentTaxesPayRec = instalmentCurrentPayRec - cyIncomeTaxExpense;
		let diffCRA = parseFloat((instalmentCurrentPayRec - parseFloat(craBal)).toFixed(2));

		return (
			<div className="summary-container">
				<div className="summary-container__inputs">
					<TextFieldInput title="Opening Balance" name="openingBal" onChange={this.onNumericChange} value={openingBal} autoComplete="off" />
					<div className="summary-container__inputs__spacer" />
					<TextFieldInput title="Per CRA" name="craBal" onChange={this.onNumericChange} value={craBal} autoComplete="off" />
					<div className="summary-container__inputs__spacer" />
					<TextFieldInput
						title="Current Year Taxes"
						name="cyIncomeTaxExpense"
						onChange={this.onNumericChange}
						value={cyIncomeTaxExpense}
						autoComplete="off"
					/>
					<div className="summary-container__inputs__diff-cra">Diff CRA = {diffCRA}</div>
				</div>

				<div className="summary-container__summary">
					<div className="t__header">
						<div className="list-column list-column--xl"></div>
						<div className="list-column list-column--s">(Installments)/ (prepayments)/ refunds</div>
						<div className="list-column list-column--s">(Interest Income)</div>
						<div className="list-column list-column--s">Interest Paid</div>
						<div className="list-column list-column--s">Income Tax Expense</div>
						<div className="list-column list-column--s">(Payable)/ Receivable</div>
					</div>

					<div className="list-item list-item--stripe">
						<div className="list-column list-column--xl">
							<strong>Balance, beginning of year</strong>
						</div>
						<div className="list-column list-column--s" />
						<div className="list-column list-column--s" />
						<div className="list-column list-column--s" />
						<div className="list-column list-column--s" />
						<div className="list-column list-column--s">{this.formatNumber(openingBal)}</div>
					</div>

					<div className="list-item">
						<div className="list-column list-column--xl">Interest and penalty</div>
						<div className="list-column list-column--s" />
						<div className="list-column list-column--s">{this.formatNumber(cyInterestIncome)}</div>
						<div className="list-column list-column--s">{this.formatNumber(cyInterestPaid)}</div>
						<div className="list-column list-column--s" />
						<div className="list-column list-column--s">{this.formatNumber(intAndPenPayRec)}</div>
					</div>

					<div className="list-item list-item--stripe">
						<div className="list-column list-column--xl">Instalment paid during the year for {priorYear}</div>
						<div className="list-column list-column--s">{this.formatNumber(pyInstalment)}</div>
						<div className="list-column list-column--s" />
						<div className="list-column list-column--s" />
						<div className="list-column list-column--s" />
						<div className="list-column list-column--s">{this.formatNumber(instalmentRecPriorPay)}</div>
					</div>

					<div className="list-item">
						<div className="list-column list-column--xl">Instalment paid during the year for {currentYear}</div>
						<div className="list-column list-column--s">{this.formatNumber(cyInstalment)}</div>
						<div className="list-column list-column--s" />
						<div className="list-column list-column--s" />
						<div className="list-column list-column--s" />
						<div className="list-column list-column--s">{this.formatNumber(instalmentCurrentPayRec)}</div>
					</div>

					<div className="list-item list-item--stripe">
						<div className="list-column list-column--xl">Current year taxes</div>
						<div className="list-column list-column--s" />
						<div className="list-column list-column--s" />
						<div className="list-column list-column--s" />
						<div className="list-column list-column--s">{this.formatNumber(cyIncomeTaxExpense)}</div>
						<div className="list-column list-column--s">{this.formatNumber(currentTaxesPayRec)}</div>
					</div>

					<div className="list-item">
						<div className="list-column list-column--xl">
							<strong>
								Balance as of {month} {day}, {currentYear}
							</strong>
						</div>
						<div className="list-column list-column--s">
							<strong>{this.formatNumber(closingInstalmentBalance)}</strong>
						</div>
						<div className="list-column list-column--s">
							<strong>{this.formatNumber(cyInterestIncome)}</strong>
						</div>
						<div className="list-column list-column--s">
							<strong>{this.formatNumber(cyInterestPaid)}</strong>
						</div>
						<div className="list-column list-column--s">
							<strong>{this.formatNumber(incomeTaxExpenseBalance)}</strong>
						</div>
						<div className="list-column list-column--s">
							<strong>{this.formatNumber(currentTaxesPayRec)}</strong>
						</div>
					</div>
				</div>

				<div className="summary-container__actions btn-group">
					<div className="btn" onClick={this.downloadSummary}>
						Download Summary
					</div>
				</div>
			</div>
		);
	}
}

export default ReconSummary;
