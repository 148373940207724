import React from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";

import AuthService from "../../../services/AuthService";
import UtilityService from "../../../services/UtilityService";

import TextFieldInput from "../../common/text_field_input/TextFieldInput";
import WComponent from "../../common/WComponent";

import "./reset-password.css";

/**
 * @class ResetPassword
 * @description Component for the reset password page
 * @extends {WComponent<ResetPassword.propTypes>}
 */
export default class ResetPassword extends WComponent {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			resetPasswordState: "initial"
		};
	}

	onSubmitResetPassword = async e => {
		e.preventDefault();

		const { email } = this.state;
		if (this.isSubmitResetPasswordDisabled()) {
			return;
		}

		await AuthService.resetPassword({ email });

		await this.update({
			resetPasswordState: "submitted"
		});
	};

	isSubmitResetPasswordDisabled = () => {
		const { email } = this.state;
		return !UtilityService.isTextInEmailFormat({ text: email });
	};

	initialResetPasswordRender = () => {
		const { onSubmitResetPassword, isSubmitResetPasswordDisabled } = this;
		const { email } = this.state;

		return (
			<div className="reset-password">
				<div className="reset-password__form">
					<div className="reset-password__form__heading">
						<p className="reset-password__form__heading__title">Forgot your password?</p>
						<p className="reset-password__form__heading__subtitle">Enter the email address you use to sign in to Workflow.</p>
					</div>

					<TextFieldInput
						name={"Email"}
						title={"Email"}
						autoComplete={"off"}
						type={"email"}
						view="auth"
						value={email}
						onChange={e => this.update({ email: e.target.value })}
					/>

					<div
						className={clsx("reset-password__form__btn", { "reset-password__form__btn--disabled": isSubmitResetPasswordDisabled })}
						onClick={onSubmitResetPassword}
					>
						Reset Password
					</div>

					<hr className="reset-password__form__hr"></hr>
					<div className="reset-password__form__secondary__action">
						<p className="reset-password__form__secondary__action__message">Already have a Workflow account?</p>
						<Link className="reset-password__form__secondary__action__link" to="/login">
							Sign in
						</Link>
					</div>
				</div>
			</div>
		);
	};

	resetPasswordEntrySubmittedRender = () => {
		const { history } = this.props;

		return (
			<div className="reset-password">
				<div className="reset-password__form">
					<div className="reset-password__form__heading">
						<p className="reset-password__form__heading__title">Reset password email sent</p>
						<p className="reset-password__form__heading__subtitle">
							If there is an account associated with this email address you will receive an email with a link to reset your password.
						</p>
					</div>
					<div className="reset-password__form__btn reset-password__form__btn--email-sent" onClick={() => history.push("/login")}>
						Go Back to Sign in
					</div>
				</div>
			</div>
		);
	};

	render() {
		const { initialResetPasswordRender, resetPasswordEntrySubmittedRender } = this;
		return <>{this.state.resetPasswordState === "initial" ? initialResetPasswordRender() : resetPasswordEntrySubmittedRender()}</>;
	}
}
