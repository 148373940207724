import moment from "moment";

const NOW = moment();

const START_OF_TODAY = NOW.clone().startOf("day");
const END_OF_TODAY = NOW.clone().endOf("day");

export const ORDER_QUERIES = {
	name: "name",
	description: "description",
	assignedUser: "assignedUser",
	status: "status",
	deadline: "deadline"
};

export const STATUSES = {
	pending: {
		id: "pending",
		name: "Pending",
		color: "rgba(215, 215, 215, 0.4)"
	},
	ready: {
		id: "ready",
		name: "Ready",
		color: "rgb(208, 208, 208)"
	},
	in_progress: {
		id: "in_progress",
		name: "In Progress",
		color: "rgba(255, 237, 30, 0.38)"
	},
	done: {
		id: "done",
		name: "Done",
		color: "rgba(126, 211, 33, 0.4)"
	}
};

export const DEADLINES = {
	overdue: {
		id: "overdue",
		name: "Overdue",
		color: "rgb(255 160 160 / 40%)",
		range: {
			start: END_OF_TODAY.clone().subtract(5000, "days"),
			end: END_OF_TODAY.clone().subtract(1, "days")
		}
	},
	today: {
		id: "today",
		name: "Today",
		color: "rgb(208, 208, 208)",
		range: {
			start: START_OF_TODAY.clone(),
			end: END_OF_TODAY.clone()
		}
	},
	thisWeek: {
		id: "this_week",
		name: "This Week",
		color: "rgba(255, 237, 30, 0.38)",
		range: {
			start: START_OF_TODAY.clone().weekday(0),
			end: START_OF_TODAY.clone().weekday(6)
		}
	},
	nextWeek: {
		id: "next_week",
		name: "Next Week",
		color: "rgba(126, 211, 33, 0.4)",
		range: {
			start: START_OF_TODAY.clone().weekday(6),
			end: START_OF_TODAY.clone()
				.weekday(6)
				.add(7, "days")
		}
	},
	afterNextWeek: {
		id: "after_next_week",
		name: "After Next Week",
		color: "rgba(215, 215, 215, 0.4)",
		range: {
			start: START_OF_TODAY.clone().weekday(14),
			end: START_OF_TODAY.clone()
				.weekday(14)
				.add(5000, "days")
		}
	}
};
