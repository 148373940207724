import React, { Component } from "react";
import "./tabs.css";
import clsx from "clsx";

class Tabs extends Component {
	render() {
		let { className, onSelect, selected, children } = this.props;

		const childrenWithProps = React.Children.map(children, child => {
			if (React.isValidElement(child)) {
				return React.cloneElement(child, { onSelect, selected });
			}
			return child;
		});

		return <div className={clsx("tabs", className)}>{childrenWithProps}</div>;
	}
}

export default Tabs;
