import React, { Component } from "react";
import { Link } from "react-router-dom";

class NavbarLink extends Component {
	render() {
		const { isActiveTab, label, value, to, icon, handleTabClick, onClick = () => {} } = this.props;

		return (
			<Link
				className={`navbar-link ${isActiveTab(value)}`}
				to={to}
				onClick={() => {
					handleTabClick(value);

					if (onClick) {
						onClick();
					}
				}}
			>
				{icon}
				<span className="navbar-link__label">{label}</span>
			</Link>
		);
	}
}

export default NavbarLink;
