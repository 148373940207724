import AuthService from "../../services/AuthService";
import WComponent from "../common/WComponent";

class AuthorizationChecker extends WComponent {
	constructor(props) {
		super(props);
		this.intervalId = null;
	}

	componentDidMount() {
		const { checkAuthorization } = this;
		const tenMinsInMs = 600000;
		this.intervalId = setInterval(checkAuthorization, tenMinsInMs);
	}

	componentWillUnmount() {
		const { intervalId } = this;
		clearInterval(intervalId);
	}

	checkAuthorization = () => {
		AuthService.validateToken();
	};

	render() {
		return null;
	}
}

export default AuthorizationChecker;
