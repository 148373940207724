import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Navbar from "../navbar/Navbar.js";
import Clients from "../clients/Clients.js";
import ClientDetails from "../clients/ClientDetails/ClientDetails.js";
import Engagements from "../workflow/Engagements";
import Engagement from "../workflow/engagement/Engagement.js";
import CompanySettings from "../settings/CompanySettings.js";
import StaffSettings from "../settings/StaffSettings.js";
import Flows from "../settings/Flows/Flows";
import Tasks from "../tasks/Tasks";
import Fields from "../settings/Fields/Fields";
import ClientSummary from "../dashboard/ClientSummary.js";
import Users from "../users/Users";
import AuthorizationChecker from "../auth/AuthorizationChecker.js";

import UserService from "../../services/UserService";
import ClientsBeta from "../clients/ClientsBeta.js";
import EngagementTemplates from "../workflow/EngagementTemplates/EngagementTemplates.js";
import FloatingStatusSelectorContainer from "../common/status_container/FloatingStatusSelectorContainer.js";
import AuthorizedRoute from "../common/AuthorizedRoute.js";
import FloatingUserAssignerContainer from "../common/user_assigner/FloatingUserAssignerContainer.js";
import FloatingNotesContainer from "../notes/FloatingNotesContainer.js";
import ClientGroups from "../clients/ClientGroups/ClientGroups.js";
import NotFound from "../common/not_found/NotFound.js";
import CLIENTS from "../../constants/Clients";

class Main extends Component {
	constructor(props) {
		super(props);

		let userData = UserService.getUserData();
		this.state = {
			assignedUserId: userData.userId
		};
	}

	render() {
		let { assignedUserId } = this.state;

		let isAuthorizedForAdminOrManager = UserService.isAdministrator() || UserService.isManager();
		return (
			<div>
				<AuthorizationChecker />
				<FloatingStatusSelectorContainer />
				<FloatingUserAssignerContainer />
				<FloatingNotesContainer />
				<Navbar />
				<Switch>
					<Route exact path="/" component={ClientSummary} />
					<Route exact path="/summary" component={ClientSummary} />
					<Route exact path="/clients/groups" component={ClientGroups} />
					<Route
						exact
						path="/clients/:clientType"
						render={props => {
							let { clientType } = props.match.params;
							let isAllowed = !!CLIENTS.clientTypes[clientType];
							if (!isAllowed) {
								return <NotFound />;
							}
							return <Clients {...props} />;
						}}
					/>
					<Route
						exact
						path="/clients/:clientType/:clientId"
						render={props => {
							let { clientType, clientId } = props.match.params;

							if (clientId === "new") {
								return <ClientDetails {...props} />;
							}

							let isAllowed = !!CLIENTS.clientTypes[clientType] && !isNaN(clientId);
							if (!isAllowed) {
								return <NotFound />;
							}
							return <ClientDetails {...props} />;
						}}
					/>
					<Route exact path="/engagements" render={props => <Engagements title={"Engagements"} {...props} showViews={true} showFilters={true} />} />
					<Route exact path="/engagements/:engagementId" component={Engagement} />
					<Route exact path="/tasks" render={props => <Tasks title={"Tasks"} showViews={true} showFilters={true} {...props} />} />
					<AuthorizedRoute exact path="/settings/templates" component={EngagementTemplates} isAuthorized={isAuthorizedForAdminOrManager} />
					<AuthorizedRoute exact path="/settings/company" component={CompanySettings} isAuthorized={UserService.isAdministrator()} />
					<AuthorizedRoute exact path="/settings/flows" component={Flows} isAuthorized={isAuthorizedForAdminOrManager} />
					<AuthorizedRoute exact path="/settings/fields" component={Fields} isAuthorized={isAuthorizedForAdminOrManager} />
					<AuthorizedRoute exact path="/settings/users" component={Users} isAuthorized={isAuthorizedForAdminOrManager} />
					<Route
						exact
						path="/settings/users/:userId"
						render={props => {
							let { userId } = props.match.params;
							userId = parseInt(userId);
							const isEditingMyProfile = userId === assignedUserId;
							const isAllowed = isAuthorizedForAdminOrManager || isEditingMyProfile;
							if (!isAllowed) {
								return <Redirect to="/" />;
							}
							return <StaffSettings {...props} />;
						}}
					/>
					<Route path="*" component={NotFound} />
				</Switch>
			</div>
		);
	}
}

export default Main;
