import React from "react";
import { Redirect } from "react-router-dom";
import { toast as alert } from "react-toastify";
import { Link } from "react-router-dom";

// Services
import AuthService from "../../../services/AuthService";
import UserService from "../../../services/UserService";

// Common Components
import WComponent from "../../common/WComponent";
import TextFieldInput from "../../common/text_field_input/TextFieldInput";

// Styling
import "./login.css";

class Login extends WComponent {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			password: "",
			loading: false,
			showPassword: false
		};
	}

	signIn = async () => {
		let { email, password } = this.state;
		let { history } = this.props;

		await this.update({ loading: true });

		try {
			await AuthService.login({ email, password });
			let userData = UserService.getUserData();
			await this.update({ loading: false });
			history.push("/");
			alert.success("Welcome " + userData.firstName + " !");
		} catch (error) {
			alert.error("Invalid username or password");
			console.log(error);
		}
	};

	onEmailChange = event => {
		event.preventDefault();
		this.update({ email: event.target.value });
	};

	onPasswordChange = event => {
		event.preventDefault();
		this.update({ password: event.target.value });
	};

	handleKeyDown = e => {
		if (e.key === "Enter") {
			e.preventDefault(); // Prevent default form submission
			this.signIn(); // Call the signIn method
		}
	};

	render() {
		const { showPassword, email, password } = this.state;

		let userData = UserService.getUserData();
		if (userData) {
			return <Redirect to={"/"} />;
		}

		return (
			<form className="login" onKeyDown={this.handleKeyDown}>
				<div className="login__body">
					<div className="login__left-side">
						<span className="login__body__title">Welcome back</span>
						<span className="login__body__subtitle">Please enter your details.</span>
						<div className="login__body__form">
							<TextFieldInput title="Email" name="email" value={email} autoComplete="off" onChange={this.onEmailChange} view="auth" />
							<TextFieldInput
								title="Password"
								name="password"
								type={showPassword ? "text" : "password"}
								value={password}
								autoComplete="off"
								onChange={this.onPasswordChange}
								view="auth"
							/>
							<Link className="login__body__form__inputs__reset-pass-link" to="/reset-password">
								<span className="login__body__form__inputs__reset-pass-link__text">Forgot Password?</span>
							</Link>
						</div>
						<div className="login__body__form__btn" onClick={this.signIn}>
							Login
						</div>
					</div>
					<div className="login__right-side">
						<img className="login__right-side__illustration" src="/assets/login-illustration.svg" alt="accounting illustration" />
					</div>
				</div>
			</form>
		);
	}
}

export default Login;
