import Modal from "react-responsive-modal";

export const DeleteModal = ({ show, onClose }) => {
	return (
		<Modal open={show} onClose={onClose} center>
			<div className="modal-title">Delete Engagement?</div>
			<div className="modal-content">Are you sure you want to remove this engagement?</div>
			<div className="btn-group">
				<div onClick={() => onClose(true)} className="engagement-button">
					Yes
				</div>
				<div onClick={() => onClose(false)} className="engagement-button">
					No
				</div>
			</div>
		</Modal>
	);
};
