import React, { Component } from "react";

import FloatingContainerService from "../../../services/FloatingContainerService";
import FloatingUserAssigner from "./FloatingUserAssigner";

class FloatingUserAssignerContainer extends Component {
	constructor(props) {
		super(props);

		this.currentRef = null;
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	componentDidMount() {
		FloatingContainerService.storeReference({
			name: "userAssigner",
			ref: this.currentRef,
			width: 465,
			height: 560
		});
	}

	render() {
		return <FloatingUserAssigner ref={ref => (this.currentRef = ref)} />;
	}
}

export default FloatingUserAssignerContainer;
