export const ORDER_QUERIES = {
	name: "name",
	createdAt: "createdAt"
};

export const DEFAULT_RELATIVE_DUE_DATE = {
	dateType: {
		id: "daysAfter",
		name: "Days After"
	},
	value: 5
};
