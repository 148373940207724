import React from "react";
import "./page-heading.css";
import clsx from "clsx";

class PageHeading extends React.Component {
	render() {
		const { section, title, className } = this.props;
		return (
			<div className={clsx("page-heading", className)}>
				<div className="page-heading__project">{section}</div>
				<div className="page-heading__title">{title}</div>
			</div>
		);
	}
}

export default PageHeading;
