export default {
	clientTypes: {
		corporate: {
			id: "corporate",
			value: "Corporate"
		},
		personal: {
			id: "personal",
			value: "Personal"
		},
		trust: {
			id: "trust",
			value: "Trusts"
		}
	},
	statuses: [
		{
			key: "active",
			value: "Active"
		},
		{
			key: "inactive",
			value: "Inactive"
		},
		{
			key: "dissolved",
			value: "Dissolved"
		},
		{
			key: "soft_deleted",
			value: "Deleted"
		}
	],
	contactOrderQueries: {
		firstName: "first_name",
		lastName: "last_name",
		email: "email",
		phone: "phone",
		dob: "dob",
		sin: "sin"
	},
	customFieldTypes: {
		string: "string",
		boolean: "boolean",
		number: "number",
		list: "list"
	}
};
