import React from "react";
import "./not-found.css";

const NotFound = () => (
	<div className="not-found-container">
		<div>
			<h1 className="not-found-container__title">404</h1>
			<p className="not-found-container__message">Page Not Found</p>
		</div>
	</div>
);

export default NotFound;
