import { Component } from "react";

export default class WComponent extends Component {
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};
}
