import Kichiri from "./KichiriService";
import UserService from "./UserService";

const ContactService = {
	/**
	 * Searches for contacts based on the search term
	 *
	 * @param {String} searchTerm
	 * @param {Number} clientId
	 * @return {Promise}
	 */
	async searchContacts({ searchTerm, clientId }) {
		try {
			let user = UserService.getUserData();

			let response = await Kichiri.contact.search({ searchTerm, clientId }, {}, user.authToken);

			let contacts = response.data.map(contact => {
				return { ...contact, name: `${contact.first_name} ${contact.last_name} - ${contact.email}` };
			});

			return contacts;
		} catch (error) {
			console.log(error);
		}

		return [];
	},

	/**
	 * Assigns a Contact to Client
	 *
	 * @param {Number} contactId
	 * @param {Number} clientId
	 * @return {Promise}
	 */
	async assignContactToClient({ contactId, clientId }) {
		try {
			let user = UserService.getUserData();

			let response = await Kichiri.contact.assign({ contactId, clientId }, {}, user.authToken);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return [];
	},

	/**
	 * Fetch a list of contacts for a client
	 *
	 * @param {Number} clientId
	 * @return {Promise}
	 */
	async getListOfContacts({ clientId }) {
		try {
			let query = {
				clientId: clientId
			};
			let userData = UserService.getUserData();
			let response = await Kichiri.contact.list({}, query, userData.authToken);

			return response;
		} catch (error) {
			console.log(error);
		}

		return [];
	},

	/**
	 * Fetch a contact's information
	 * @param {Number} contactId
	 * @param {Number} clientId
	 * @return {Promise}
	 */
	async getContact({ contactId, clientId }) {
		try {
			let userData = UserService.getUserData();
			let response = await Kichiri.contact.get({ contactId }, { clientId }, userData.authToken);
			return response;
		} catch (error) {
			console.log(error);
		}

		return [];
	},

	/**
	 * Create a contact for a client
	 * @param {Number} clientId
	 * @param {String} firstName
	 * @param {String} lastName
	 * @param {String} email
	 * @param {String} phone
	 * @param {String} dob
	 * @param {String} sin
	 * @param {String} address
	 * @param {Boolean} isPrimary
	 */
	async createContact({ clientId, firstName, lastName, email, phone, dob, sin, address, isPrimary }) {
		try {
			let userData = UserService.getUserData();
			await Kichiri.contact.create(
				{
					clientId,
					firstName,
					lastName,
					email,
					phone,
					dob,
					sin,
					address,
					isPrimary
				},
				{},
				userData.authToken
			);
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Update a contact information
	 * @param {Number} contactId
	 * @param {Number} clientId
	 * @param {String} firstName
	 * @param {String} lastName
	 * @param {String} email
	 * @param {String} phone
	 * @param {String} dob
	 * @param {String} sin
	 * @param {String} address
	 * @param {Boolean} isPrimary
	 */
	async updateContact({ contactId, clientId, firstName, lastName, email, phone, dob, sin, address, isPrimary }) {
		try {
			let userData = UserService.getUserData();
			await Kichiri.contact.update(
				{
					contactId,
					clientId,
					firstName,
					lastName,
					email,
					phone,
					dob,
					sin,
					address,
					isPrimary
				},
				{},
				userData.authToken
			);
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Removes a contact
	 * @param {Number} clientId
	 * @param {Number} contactId
	 */
	async removeContact({ clientId, contactId }) {
		try {
			let userData = UserService.getUserData();
			await Kichiri.client.remove_contact({ clientId, contactId }, {}, userData.authToken);
		} catch (error) {
			console.log(error);
		}
	}
};

export default ContactService;
