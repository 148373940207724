import React from "react";

import UserService from "../../../services/UserService";
import WComponent from "../WComponent";
import * as Icon from "react-feather";
import "./role-picker.css";

/**
 * @deprecated This class is deprecated.
 * Please use SearchableDropdown instead.
 */
export default class RolePicker extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			show: false,

			search: props.roleId,

			results: []
		};
	}

	handleClick = e => {
		if (!this.node) {
			return;
		}

		if (this.node.contains(e.target)) {
			return;
		}

		this.update({
			show: false
		});
	};

	componentDidMount() {}

	componentDidUpdate(prevProps) {
		let { roleId } = this.props;
		if (prevProps.roleId !== roleId) {
			this.update({
				search: roleId
			});
		}
	}

	searchRoles = async () => {
		let { search } = this.state;
		let roles = await UserService.fetchRoles({ searchTerm: search });

		await this.update({
			results: roles
		});
	};

	onSearchChange = async e => {
		await this.update({
			search: e.target.value
		});

		this.searchRoles();
	};

	clear = async () => {
		await this.update({
			show: false,
			search: ""
		});
	};

	onSelect = async item => {
		await this.update({
			show: false,
			search: item.name
		});

		if (this.props.onSelect) {
			this.props.onSelect(item);
		}
	};

	onInputFocus = async () => {
		await this.update({
			show: true
		});

		this.searchRoles();
	};

	onInputBlur = async event => {
		// We timeout here since the onBlur event occurs before the onSelect in the child, so we need to hide right after the events fire for the child component
		setTimeout(() => {
			this.update({
				show: false
			});
		}, 100);
	};

	render() {
		let { searchPlaceHolder, search, show, results } = this.state;

		return (
			<div className="role-picker">
				<Icon.User size={14} color="var(--primary-main)" />
				<input
					className="role-picker__input"
					type="text"
					name="search"
					onChange={this.onSearchChange}
					onFocus={this.onInputFocus}
					onBlur={this.onInputBlur}
					placeholder={searchPlaceHolder}
					value={search}
					autoComplete="off"
				/>
				{show && results.length > 0 && (
					<div className="role-picker__list">
						{results.map(item => {
							return (
								<div className="role-picker__list__item" key={item.id} onMouseDown={() => this.onSelect(item)}>
									{item.name}
								</div>
							);
						})}
					</div>
				)}
			</div>
		);
	}
}
