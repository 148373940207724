// External Libraries
import React from "react";
import * as Icon from "react-feather";
import { Link, Redirect } from "react-router-dom";
import WComponent from "../common/WComponent";
import moment from "moment";
import clsx from "clsx";

// Constants
import USERS from "../../constants/Users";

// Components
import Teams from "./Teams";

// Common Components
import Loader from "../common/loader/Loader";
import SearchInput from "../common/search_input/SearchInput";
import Toggle from "../common/toggle/Toggle";
import Button from "../common/button/Button";

import PageHeading from "../common/page_heading/PageHeading";

// Services
import UserService from "../../services/UserService";

// Styling
import "./users.css";
import Tab from "../common/tabs/tab/Tab";
import Tabs from "../common/tabs/Tabs";

class Users extends WComponent {
	constructor() {
		super();
		this.state = {
			loading: false,
			view: "users",

			users: [],
			search: "",
			sort: [{ field: USERS.orderQueries.name, order: "asc" }]
		};
	}

	componentDidMount() {
		this.resetComponent();
	}

	async resetComponent() {
		this.update({
			loading: true
		});

		await this.fetchUsers();

		this.update({
			loading: false
		});
	}

	async fetchUsers() {
		let { sort, search } = this.state;

		let users = await UserService.fetchUsers({ isDetailed: true, sort, search });

		await this.update({
			users
		});
	}

	onSearch = async event => {
		await this.update({ search: event.target.value });
		await this.fetchUsers();
	};

	switchView = view => {
		this.update({
			view: view.id
		});
	};

	sortBy = async field => {
		let { sort } = this.state;

		let index = -1;
		for (let i = 0; i < sort.length; i++) {
			if (sort[i].field === field) {
				index = i;
				break;
			}
		}

		if (index === -1) {
			sort.push({ field, order: "desc" });
		} else {
			let sortField = sort[index];

			sort.splice(index, 1);
			if (sortField.order === "desc") {
				sortField.order = "asc";

				sort.push(sortField);
			}
		}

		await this.update({
			sort
		});

		await this.fetchUsers();
	};

	renderSortIcon = field => {
		let { sort } = this.state;

		let index = -1;
		for (let i = 0; i < sort.length; i++) {
			if (sort[i].field === field) {
				index = i;
				break;
			}
		}

		if (index === -1) {
			return null;
		}

		return sort[index].order === "desc" ? (
			<div style={{ fontSize: 10 }}>
				<Icon.ChevronUp size={10} />
				{index + 1}
			</div>
		) : (
			<div style={{ fontSize: 10 }}>
				<Icon.ChevronDown size={10} />
				{index + 1}
			</div>
		);
	};

	renderUsers() {
		let { history } = this.props;

		let { users, loading, search } = this.state;

		let userData = UserService.getUserData();

		if (userData.groupId === 2) {
			return <Redirect to={"/dashboard"} />;
		}

		return (
			<>
				<div className="users__user-view__header">
					<Button
						view="secondary"
						onClick={() => {
							history.replace("/settings/users/new");
						}}
						icon={<Icon.Plus size={18} />}
					/>

					<SearchInput placeholder="Search..." value={search} onChange={this.onSearch} />
				</div>

				{loading && (
					<div className="container-loader">
						<Loader />
					</div>
				)}

				{users.length > 0 && !loading && (
					<div className={clsx("t", "t--compact")}>
						<div className="t__header">
							<div onClick={() => this.sortBy(USERS.orderQueries.name)} className="t__header__item">
								Name {this.renderSortIcon(USERS.orderQueries.name)}
							</div>
							<div onClick={() => this.sortBy(USERS.orderQueries.title)} className="t__header__item">
								Role {this.renderSortIcon(USERS.orderQueries.title)}
							</div>
							<div onClick={() => this.sortBy(USERS.orderQueries.email)} className="t__header__item">
								Email {this.renderSortIcon(USERS.orderQueries.email)}
							</div>
							<div onClick={() => this.sortBy(USERS.orderQueries.phone)} className="t__header__item">
								Phone {this.renderSortIcon(USERS.orderQueries.phone)}
							</div>
							<div onClick={() => this.sortBy(USERS.orderQueries.createdAt)} className="t__header__item">
								Join Date {this.renderSortIcon(USERS.orderQueries.createdAt)}
							</div>
						</div>

						{users.map((user, index) => {
							let odd = index % 2 === 0;

							return (
								<div className={clsx("t__row", { "t__row--stripe": odd })} key={index}>
									<div className="t__row__item" onClick={() => this.props.history.push(`/settings/users/${user.id}`)}>
										{user.first_name} {user.last_name}
									</div>
									<div className="t__row__item">{user.title}</div>
									<div className="t__row__item">{user.email}</div>
									<div className="t__row__item">{user.phone}</div>
									<div className="t__row__item">{moment(user.created_at).format("LL")}</div>
								</div>
							);
						})}
					</div>
				)}
			</>
		);
	}

	render() {
		let { view } = this.state;
		let { switchView } = this;

		return (
			<>
				<div className="users">
					<PageHeading section="Settings" title={"Users"} />

					<Tabs onSelect={switchView} selected={view}>
						<Tab id={"users"} icon={<Icon.User size={18} strokeWidth={1.5} />} text="Users" />
						<Tab id={"teams"} icon={<Icon.Users size={18} strokeWidth={1.5} />} text="Teams" />
					</Tabs>

					{view === "users" && this.renderUsers()}
					{view === "teams" && <Teams />}
				</div>
			</>
		);
	}
}

export default Users;
