import React from "react";
import Modal from "react-responsive-modal";
import moment from "moment";
import { toast as alert } from "react-toastify";

import EngagementService from "../../../services/EngagementService";

import "./next-engagement-modal.css";
import WComponent from "../../common/WComponent";

class NextEngagementModal extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			show: false,
			engagementId: this.props.engagementId,
			term: 1
		};

		this.onYearEndSelect = this.onYearEndSelect.bind(this);
	}

	async componentDidMount() {
		await this.resetComponent();
	}

	async componentDidUpdate(prevProps) {
		let { show } = this.props;
		if (prevProps.show !== show) {
			await this.resetComponent();
		}
	}

	async resetComponent() {
		let { show } = this.props;

		await this.update({ show });
		await this.fetchEngagement();
	}

	getNextYearEndDisplay(date, term) {
		return moment(date)
			.add(term, "months")
			.format("MMMM Do, YYYY");
	}

	getNextYearEnd(date, term) {
		return moment(date)
			.add(term, "months")
			.format("YYYY-MM-DD");
	}

	async fetchEngagement() {
		let { engagementId } = this.state;

		try {
			let response = await EngagementService.get({ engagementId });

			let engagement = response.data;
			let flowStates = await EngagementService.fetchFlowStates({ flowCode: engagement.flow.code });

			let date = engagement.fiscal_year_end;
			let nextMonth = this.getNextYearEndDisplay(date, 1);
			let nextQuarter = this.getNextYearEndDisplay(date, 3);
			let nextYear = this.getNextYearEndDisplay(date, 12);

			let assignedUsers = engagement.engagement_users.map(eu => {
				return {
					user_id: eu.user_id,
					role_id: eu.role_id
				};
			});

			await this.update({
				clientName: engagement.Client ? engagement.Client.name : "No Assigned Client",
				engagementId: engagement.id,

				name: engagement.name,

				teamId: engagement.team_id,
				clientId: engagement.Client ? engagement.Client.id : 0,
				flowId: engagement.flow_id,
				flowStateId: flowStates[0].id, // Take the first flow state from the response

				fiscalYearEnd: engagement.fiscal_year_end,
				frequency: engagement.frequency,

				assignedUsers,

				nextMonth: nextMonth,
				nextQuarter: nextQuarter,
				nextYear: nextYear
			});
		} catch (error) {
			console.log(error);
		}
	}

	async createEngagement() {
		let { loading, term, name, teamId, clientId, flowId, flowStateId, frequency, assignedUsers } = this.state;

		if (loading) {
			alert.info("Creating ...");
			return;
		}

		this.update({
			loading: true
		});

		let fiscalYearEnd = this.getNextYearEnd(this.state.fiscalYearEnd, term);

		try {
			await EngagementService.create({
				name,

				teamId,
				clientId,
				flowId,
				flowStateId,

				frequency,
				fiscalYearEnd: fiscalYearEnd,

				assignedUsers
			});

			alert.success("Next engagement has been created!");
		} catch (error) {
			console.log(error);
			alert.error("Create Error!");
		}

		this.update({
			loading: false
		});
	}

	onYearEndSelect(months) {
		this.update({
			term: months
		});
	}

	isActive(months) {
		let { term } = this.state;
		return term === months ? "active-toggle" : "";
	}

	onClose = async confirm => {
		await this.update({
			show: false
		});

		if (confirm) {
			this.createEngagement();
		}
	};

	render() {
		let { show, name, nextMonth, nextQuarter, nextYear } = this.state;

		return (
			<Modal open={show} onClose={this.onClose} center>
				<div className="modal-title">Create next Engagement ...</div>
				<div className="modal-content">
					Looks like you just completed the following engagement:
					<br />
					<br />
					{name}
					<br />
					<br />
					Would you like to create next cycle's engagement?
					<br />
					<div className="next-modal-options">
						<div className={`next-modal-option ${this.isActive(1)}`} onClick={() => this.onYearEndSelect(1)}>
							Next Month: {nextMonth}
						</div>
						<div className={`next-modal-option ${this.isActive(3)}`} onClick={() => this.onYearEndSelect(3)}>
							Next Quarter: {nextQuarter}
						</div>
						<div className={`next-modal-option ${this.isActive(12)}`} onClick={() => this.onYearEndSelect(12)}>
							Next Year: {nextYear}
						</div>
					</div>
				</div>
				<div className="btn-group">
					<div onClick={() => this.onClose(true)} className="btn">
						Yes, create.
					</div>
					<div onClick={() => this.onClose(false)} className="btn">
						No leave it.
					</div>
				</div>
			</Modal>
		);
	}
}

export default NextEngagementModal;
