export default {
	titles: {
		partner: {
			key: "partner",
			value: "Firm Partner"
		},
		accountant: {
			key: "accountant",
			value: "Staff Accountant"
		},
		associate: {
			key: "associate",
			value: "Associate"
		},
		administrator: {
			key: "administrator",
			value: "Administrator"
		}
	},
	orderQueries: {
		name: "name",
		email: "email",
		phone: "phone",
		title: "title",
		createdAt: "createdAt"
	},
	roleType: {
		NO_ROLE: {
			id: 19,
			value: "No Role"
		}
	}
};
