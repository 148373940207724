import Kichiri from "./KichiriService";
import UserService from "./UserService";

const ClientPortalService = {
	/**
	 * Send email for new account setup
	 * @param {number} contactId
	 * @returns {unknown}
	 */
	async emailNewAccountSetup({ contactId }) {
		try {
			let userData = UserService.getUserData();

			let response = await Kichiri.client_portal.emailNewAccountSetup(
				{
					contactId
				},
				{},
				userData.authToken
			);

			return response;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Send email for password reset request.
	 * @param {string} contactEmail
	 * @returns {unknown}
	 */
	async sendResetPassEmail({ contactEmail }) {
		try {
			let response = await Kichiri.client_portal.emailResetPassword({
				contactEmail
			});
			return response;
		} catch (error) {
			console.log(error);
		}
	}
};

export default ClientPortalService;
