import React, { Component } from "react";
import { motion, AnimatePresence } from "framer-motion";

import UserAssigner from "./UserAssigner";

import "./floating-user-assigner.css";

const variants = {
	enter: {
		y: 0,
		x: 0,
		opacity: 1,
		transition: {
			duration: 0.2
		}
	},
	exit: {
		y: 10,
		x: 0,
		opacity: 0,
		transition: {
			duration: 0.1
		}
	}
};

class FloatingUserAssigner extends Component {
	constructor(props) {
		super(props);

		this.state = {
			show: false,
			leftPos: 0,
			topPos: 0,

			engagementName: "",
			assignedUsers: [],
			cache: []
		};

		this.floatingRef = null;
		// this.searchableDropdownComponentRef = null;
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	componentDidMount() {
		document.addEventListener("mousedown", this.onHandleClick, false);
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.onHandleClick, false);
	}

	onHandleClick = e => {
		if (this.floatingRef && this.floatingRef.contains(e.target)) {
			// the click is in the component
			return;
		}
		this.onClose();
	};

	onUpdate = ({ selectedUsers }) => {
		this.update({ cache: selectedUsers });
	};

	onAssignedUsersChanged = () => {
		let { onAssignedUsersChangeCallback, cache } = this.state;

		if (typeof onAssignedUsersChangeCallback === "function") {
			onAssignedUsersChangeCallback(cache);
		}

		this.onClose();
	};

	onClose = async () => {
		await this.update({ show: false });

		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	forceShow = async () => {
		await this.update({
			show: true
		});

		// if (this.searchableDropdownComponentRef) {
		// 	// this.searchableDropdownComponentRef.forceFocus();
		// }
	};

	setEngagementName = engagementName => {
		this.update({
			engagementName
		});
	};

	setAssignedUsers = assignedUsers => {
		this.update({
			assignedUsers
		});
	};

	setPosition = async ({ left, top }) => {
		let update = {};
		if (typeof left !== "undefined") update.leftPos = left;
		if (typeof top !== "undefined") update.topPos = top;
		await this.update(update);
	};

	setAssignedUsersChangedCallback = async callback => {
		await this.update({ onAssignedUsersChangeCallback: callback });
	};

	render() {
		let { show, leftPos, topPos, engagementName, assignedUsers } = this.state;
		let style = {};

		if (topPos || leftPos) {
			if (topPos) {
				style.top = `${topPos}px`;
			}
			if (leftPos) {
				style.left = `${leftPos}px`;
			}
		} else {
			style.bottom = "20px";
		}

		return (
			<AnimatePresence>
				{show && (
					<motion.div
						key="container"
						className="user-assigner-container"
						style={style}
						ref={ref => (this.floatingRef = ref)}
						initial="exit"
						animate="enter"
						exit="exit"
						variants={variants}
					>
						{engagementName}
						<div className="user-assigner-container__component">
							<UserAssigner onChange={this.onUpdate} users={assignedUsers} />
						</div>
						<div className="btn-group user-assigner-container__button-group">
							<div className="btn" onClick={this.onAssignedUsersChanged}>
								Update
							</div>
						</div>
					</motion.div>
				)}
			</AnimatePresence>
		);
	}
}

export default FloatingUserAssigner;
