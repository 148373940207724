import UserService from "./UserService";
import Kichiri from "./KichiriService";

const ClientService = {
	/**
	 * Returns a client's information
	 * @param {Number} clientId
	 * @return {Object}
	 */
	async getClient({ clientId }) {
		let userData = UserService.getUserData();

		try {
			let response = await Kichiri.client.get(
				{
					clientId
				},
				{},
				userData.authToken
			);

			return response;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Updates a client's information
	 * @param {Number} teamId
	 * @param {Number} seniorManagerId
	 * @param {Number} clientId
	 * @param {Number} partnerId
	 * @param {Number} managerId
	 * @param {String} name
	 * @param {Date} yearEnd
	 * @param {String} address
	 * @param {String} notes
	 * @param {String} craBusinessNumber
	 * @param {Boolean} isHst
	 * @param {Boolean} isPayroll
	 * @param {Boolean} isEht
	 * @param {Boolean} isWsib
	 * @param {String} faxNumber
	 * @param {String} status
	 * @param {String} customFields
	 * @param {String} email
	 * @param {String} description
	 * @return {Object}
	 */
	async updateClient({
		teamId,
		clientGroupId,
		seniorManagerId,
		bookkeeperManagerId,
		clientId,
		partnerId,
		managerId,
		name,
		yearEnd,
		address,
		notes,
		craBusinessNumber,
		isHst,
		isPayroll,
		isEht,
		isWsib,
		faxNumber,
		operatingName,
		status,
		customFields,
		email,
		description
	}) {
		let userData = UserService.getUserData();
		try {
			let response = await Kichiri.client.update(
				{
					teamId,
					clientGroupId,
					seniorManagerId,
					bookkeeperManagerId,
					clientId,
					partnerId,
					managerId,
					name,
					yearEnd,
					address,
					notes,
					craBusinessNumber,
					isHst,
					isPayroll,
					isEht,
					isWsib,
					faxNumber,
					operatingName,
					status,
					customFields,
					email,
					description
				},
				{},
				userData.authToken
			);

			return response;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Creates a client
	 * @param {String} type
	 * @param {Number} partnerId
	 * @param {Number} managerId
	 * @param {String} name
	 * @param {Date} yearEnd
	 * @param {String} address
	 * @param {String} notes
	 * @param {String} craBusinessNumber
	 * @param {Boolean} isHst
	 * @param {Boolean} isPayroll
	 * @param {Boolean} isEht
	 * @param {Boolean} isWsib
	 * @param {String} faxNumber
	 * @param {String} operatingName
	 * @param {Number} teamId
	 * @param {Number} seniorManagerId
	 * @param {String} customFields
	 * @param {String} email
	 * @param {String} description
	 * @return {Object}
	 */
	async createClient({
		type,
		partnerId,
		managerId,
		name,
		yearEnd,
		address,
		notes,
		craBusinessNumber,
		isHst,
		isPayroll,
		isEht,
		isWsib,
		faxNumber,
		operatingName,
		teamId,
		seniorManagerId,
		bookkeeperManagerId,
		customFields,
		email,
		status = "active",
		description
	}) {
		let userData = UserService.getUserData();

		try {
			let response = await Kichiri.client.create(
				{
					companyId: userData.companyId,
					type,
					partnerId,
					managerId,
					name,
					yearEnd,
					address,
					notes,
					craBusinessNumber,
					isHst,
					isPayroll,
					isEht,
					isWsib,
					faxNumber,
					operatingName,
					teamId,
					seniorManagerId,
					bookkeeperManagerId,
					status,
					customFields,
					email,
					description
				},
				{},
				userData.authToken
			);

			return response;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Returns the sin number in displayed format
	 * @param {String} sin
	 * @return {String}
	 */
	concatSin({ sin }) {
		let concat = "";

		// replace a dash with an empty string
		sin = sin.replace(/-/g, "");

		// Loop through the string
		for (var i = 0; i < sin.length; i++) {
			concat += sin[i];

			// if the 3rd or 6th index of the string has been reached
			if (i === 2 || i === 5) {
				// add a dash
				concat += "-";
			}
		}

		return concat;
	},

	/**
	 * Returns the sin number in displayed subtracted format
	 * @param sin
	 * @return {String}
	 */
	concatSinMinus({ sin }) {
		// remove the last element in the string
		sin = sin.substring(0, sin.length - 1);
		return sin;
	},

	/**
	 * Deletes a client
	 * @param {Number} clientId
	 * @return {Boolean}
	 */
	async deleteClient({ clientId }) {
		let { authToken } = UserService.getUserData();

		try {
			await Kichiri.client.delete({ clientId }, {}, authToken);

			return true;
		} catch (error) {
			console.log(error);
		}

		return false;
	},

	/**
	 * Fetches the entire client summary
	 *
	 * @param {Number} limit
	 * @param {Number} offset
	 * @param {Number} partnerId
	 * @param {Number} assignedUserId
	 * @param {Number} bookkeeperId
	 * @param {String} type
	 * @param {String} search
	 * @returns {Object}
	 */
	async fetchClientSummary({ limit = 50, offset = 0, partnerId = 0, assignedUserId = 0, bookkeeperId = 0, type, search = "" }) {
		let user = UserService.getUserData();

		try {
			let response = await Kichiri.client.summary(
				{},
				{
					limit,
					offset,
					partnerId,
					assignedUserId,
					bookkeeperId,
					type,
					search
				},
				user.authToken
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Fetches a clients info
	 * @param {Number} clientId
	 * @returns {Object}
	 */
	async fetchClient({ clientId }) {
		let user = UserService.getUserData();

		try {
			let response = await Kichiri.client.get(
				{
					clientId
				},
				{},
				user.authToken
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Fetches a client's custom field
	 * @param {Number} clientId
	 * @returns {Object}
	 */
	async fetchCustomFields({ clientId }) {
		let user = UserService.getUserData();

		try {
			let response = await Kichiri.client.getCustomFields(
				{
					clientId
				},
				{},
				user.authToken
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Fetches a list of clients given a search query
	 * @param {Object} request
	 * @returns {Array}
	 */
	async search({ request }) {
		let userData = UserService.getUserData();
		try {
			let response = await Kichiri.client.search(request, {}, userData.authToken);
			return response;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Fetches a list of clients given a search query
	 * @param {Object} request
	 * @returns {Array}
	 */
	async searchClients({ search }) {
		let userData = UserService.getUserData();
		try {
			let response = await Kichiri.client.search(
				{
					searchTerm: search
				},
				{},
				userData.authToken
			);

			return response;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Fetches a list of client groups
	 *
	 * @param {String} search
	 * @returns
	 */
	async fetchClientGroups({ search }) {
		try {
			let { authToken, companyId } = UserService.getUserData();

			let { data: clientGroups } = await Kichiri.client.listGroups({}, { companyId, searchTerm: search }, authToken);

			return clientGroups;
		} catch (error) {
			console.log(error);
		}

		return [];
	},

	/**
	 * Fetches a client group
	 *
	 * @param {Number} clientGroupId
	 * @returns
	 */
	async fetchClientGroup({ clientGroupId }) {
		try {
			let { authToken } = UserService.getUserData();

			let { data: clientGroup } = await Kichiri.client.getClientGroup({ clientGroupId }, {}, authToken);

			return clientGroup;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Updates a client group
	 *
	 * @param {Number} clientGroupId
	 * @param {String} clientGroupName
	 * @param {Array<Object>} assignedClients
	 * @param {String} status
	 * @returns
	 */
	async updateClientGroup({ clientGroupId, name, assignedClients, status }) {
		try {
			let { authToken } = UserService.getUserData();

			let { data: clientGroup } = await Kichiri.client.updateClientGroup({ clientGroupId, name, assignedClients, status }, {}, authToken);

			return clientGroup;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Creates a new client group
	 *
	 * @param {String} clientGroupName
	 * @param {Array<Object>} assignedClients
	 * @returns
	 */
	async createClientGroup({ name, assignedClients }) {
		try {
			let { authToken, companyId } = UserService.getUserData();

			let { data: clientGroup } = await Kichiri.client.createClientGroup({ name, assignedClients, companyId }, {}, authToken);

			return clientGroup;
		} catch (error) {
			console.log(error);
		}

		return null;
	}
};

export default ClientService;
